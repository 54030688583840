import {Component, Inject, TemplateRef} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

/**
 * GENERIC CONFIRMATION DIALOG
 */
@Component({
  selector: 'mx-confirm-dialog',
  template: `
    <div class="flex-col" [style.max-width]="data.maxWidth || '320px'">
      <h3 *ngIf="data.title">{{ data.title }}</h3>
      <div class="flex-col">
        <ng-container *ngIf="data.warning">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ data.warning }}</span>
          </div>
        </ng-container>
        <div class="dialog-body">
          <ng-container *ngIf="data.dialogBodyTemplateRef; else question_body">
            <ng-container *ngTemplateOutlet="data.dialogBodyTemplateRef; context: { data: data }"></ng-container>
          </ng-container>
          <ng-template #question_body>
            <span class="Text-Style">{{ data.message }}</span>
          </ng-template>
        </div>
        <div class="dialog-button-row flex-row flex-center-center">
          <button mat-stroked-button class="save-button-group button-cancel"
                  (click)="onNoClick()" *ngIf="!data.hideCancel">
            {{ data.cancelButton || 'No' }}
          </button>
          <button mat-raised-button color="primary" class="save-button-group save-button"
                  (click)="onSubmit()">
            {{ data.confirmButton || 'Yes' }}
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
        .dialog-warn-box {
            padding: 5px;
        }
    `,
  ],
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      warning: string;
      message: string;
      cancelButton: string;
      confirmButton: string;
      hideCancel: boolean;
      dialogBodyTemplateRef: TemplateRef<any>;
      maxWidth: string;
    }) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
