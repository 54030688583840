import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {PatronAccountStatus} from '../model/patron/patron-account-status';
import {Organization} from '../model/organization';
import {BLOCK_CODE, Patron} from '../model/patron/patron';
import {Environment} from '../model/environment';
import {GlobalState, PatronService, ResponseBase, selectOrganization, selectPatron} from '@raven';
import {NotificationService} from './notification.service';
import {PatronLedger} from '../model/patron/patron-ledger';
import {PatronTransactions} from '../model/patron/patron-transactions';

@Injectable({
  providedIn: 'root',
})
export class PatronLedgerService {
  patronLedgersIn = new BehaviorSubject<PatronTransactions>(null);
  patronLedgers = this.patronLedgersIn.asObservable();
  patronStatusSubject = new Subject<PatronAccountStatus>();

  organization: Organization;
  patron: Patron;

  constructor(private environment: Environment,
              private notificationService: NotificationService,
              private patronService: PatronService,
              private http: HttpClient,
              private theStore: Store<GlobalState>) {

    theStore.select(selectOrganization).subscribe((organization) => {
      this.organization = organization;
    });

    theStore.select(selectPatron).subscribe((patron) => {
      this.patron = patron;
    });

    patronService.patron$.subscribe(() => {
      this.getLedger().subscribe(ledgers => this.patronLedgersIn.next(ledgers));
    });
  }

  getById(patronLedgerId: number): Observable<PatronLedger> {
    const url = `${this.environment.apiUrl}/v1/patron-ledgers/${patronLedgerId}`;

    if (!patronLedgerId) {
      return of(null);
    }

    return this.http.get<PatronLedger>(url).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError(
          'Unable to load patron ledger record'
        );
        return of(null);
      })
    );
  }

  getLedger(): Observable<PatronTransactions> {
    if (!this.patronService.patron) return of(new PatronTransactions())
    return this.http.get<ResponseBase<PatronTransactions>>(`${this.environment.apiUrl}/patrons/v2/patrons/account/transactions`)
      .pipe(map(rb => rb.objects[0]));
  }

  refreshLedgers(): void {
    this.getLedger().subscribe((_) => this.patronLedgersIn.next(_));
  }

  refreshStatus(): void {
    if (!this.patronService.patron) return;
    this.http.get<ResponseBase<PatronAccountStatus>>(`${this.environment.apiUrl}/patrons/v2/patrons/account/status`)
      .pipe(map(rb => rb.objects[0]))
      .subscribe(status => this.patronStatusSubject.next(status));
  }

  getAccountStatus(): Observable<PatronAccountStatus> {
    this.refreshStatus();
    return this.patronStatusSubject.asObservable();
  }

  static canCheckout$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return accountStatus$.pipe(map((accountStatus) => {
      return PatronLedgerService.canCheckout(accountStatus);
    }));
  }

  static canPlaceHold$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRenew$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRequestItems$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canCheckout(accountStatus: PatronAccountStatus): boolean {
    return accountStatus?.blocks?.length === 0;
  }

  static canPlaceHold(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static canRenew(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static underMaxBalance(accountStatus: PatronAccountStatus): boolean {
    return accountStatus.currentAccountBalance < accountStatus.maximumAccountBalance;
  }

  static isExpired(accountStatus: PatronAccountStatus): boolean {
    return (accountStatus?.blocks?.indexOf(BLOCK_CODE.CARD_EXPIRED) ?? 0) >= 0;
  }
  delete(patronLedgerId: number): Observable<PatronLedger> {
    return new Observable<PatronLedger>(null);
  }
}
