import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {EMPTY, take, takeWhile} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {
  AuthService,
  ConfirmationDialogComponent,
  NotificationService,
  Organization,
  OrganizationService,
  passwordConfirmValidator,
  Patron,
  PatronService,
  PatronVerificationService,
  RegistrationExistingCard,
  RegistrationService,
  TermsComponent,
  verifyUniquePatronEmail
} from '@raven';

@Component({
  selector: 'rn-register-existing-card',
  templateUrl: './register-existing-card.component.html',
  styleUrls: ['./register-existing-card.component.scss'],
})
export class RegisterExistingCardComponent implements OnInit {
  organization: Organization;
  patron: Patron;
  existingCardForm: FormGroup;
  accountDataForm: FormGroup;
  isLoading: boolean;

  @ViewChild(MatStepper) matStepper: MatStepper;

  constructor(private authService: AuthService,
              private registrationService: RegistrationService,
              private patronService: PatronService,
              private patronVerificationService: PatronVerificationService,
              private organizationService: OrganizationService,
              private router: Router,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    // set loading
    this.isLoading = true;
    // init the form
    this.organizationService.organization$
      .pipe(
        takeWhile((organization: Organization) => {
          if (organization) {
            this.organization = organization;
            this.createForms();
            return false; // returning false to the takeWhile pipe unsubscribes, so we don't initialize forms multiple times
          }
          return true;
        })
      ).subscribe();
    // check if patron has recently gone through registration and is
    // a patron pending, if so skip to the end of this form
    this.patronService
      .patron$
      .pipe(
        take(1),
        tap((patronResponse) => {
          this.patron = patronResponse;
          if (patronResponse.id > 0 && patronResponse.emailVerified) {
            console.log('Patron is logged in, sending to dashboard');
            return this.router.navigate(['dashboard']);
          } else {
            console.log('Patron is pending or needs to verify their email');
            this.accountDataForm.get('email').setValue(patronResponse.email);
            this.matStepper.steps.get(0).completed = true;
            this.matStepper.steps.get(1).completed = true;
            this.matStepper.selectedIndex = 2;
            this.matStepper.steps.get(0).editable = false;
            this.matStepper.steps.get(1).editable = false;
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  createForms(): void {
    this.existingCardForm = this.fb.group(
      {
        barcode: ['', {validators: [Validators.required]}],
        pin: ['', {validators: [Validators.required]}],
      },
      {}
    );
    this.accountDataForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z0-9]{2,4}$")],
          verifyUniquePatronEmail(this.organization.id, this.patronService, ''),
        ],
        password: ['', {validators: [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'),]}],
        passwordConfirm: ['', {validators: [Validators.required]}],
        acceptance: ['', {validators: [Validators.required]}],
      },
      {
        validators: passwordConfirmValidator('password', 'passwordConfirm'),
      }
    );
  }

  termsAndConditions(): void {
    this.dialog.open(TermsComponent, {
      data: 'Terms and Conditions',
    });
  }

  privacyPolicy(): void {
    this.dialog.open(TermsComponent, {
      data: 'Privacy Policy',
    });
  }

  forgotPin(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Forgot Pin',
        message:
          'Unfortunately without your card number and pin we are unable to set up online access. Please contact a librarian to recover your PIN.',
        confirmButton: 'Ok',
        hideCancel: true,
      },
    });
  }

  continue(): void {
    this.isLoading = true;
    this.registrationService
      .validateExistingCard(
        this.existingCardForm.value.barcode,
        this.existingCardForm.value.pin
      )
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((email) => {
        if (email?.includes('@')) {
          const emailField = this.accountDataForm.get('email');
          emailField.setValue(email);
          emailField.disable();
          emailField.clearValidators();
          emailField.updateValueAndValidity();
        }
        this.matStepper.selected.completed = true;
        this.matStepper.selectedIndex = 1;
      });
  }

  register(): void {
    this.isLoading = true;
    const existingCardForm = this.existingCardForm.value;
    const accountDataValue = this.accountDataForm.getRawValue();
    // create the form
    const saveRegistration: RegistrationExistingCard = {
      barcode: existingCardForm.barcode,
      pinNumber: existingCardForm.pin,
      email: accountDataValue.email,
      password: accountDataValue.password,
    };
    this.registrationService.registerExistingCard(saveRegistration)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(() => {
        this.authService.login(accountDataValue.email, accountDataValue.password);

        // set a success message
        this.notificationService.showSnackbarSuccess(
          'Success! Your account has been created.'
        );
        this.matStepper.selected.completed = true;
        this.matStepper.selectedIndex = 2;
        this.matStepper.steps.get(0).editable = false;
        this.matStepper.steps.get(1).editable = false;
      });
  }

  resendEmailVerification(): void {
    this.patronVerificationService
      .sendEmailVerificationRequest(
        this.organization.id,
        this.accountDataForm.value.email
      )
      .pipe(
        catchError(() => {
          const message =
            'Error sending verification code. Please contact customer support.';
          this.notificationService.showSnackbarError(message);
          return EMPTY;
        })
      )
      .subscribe(() => {
        // set a success message
        const message =
          'The verification code has been sent. It will expire in 30 minutes. Please check your email.';
        this.notificationService.showSnackbarSuccess(message);
      });
  }
}
