import {Injectable} from '@angular/core';
import {EMPTY, Observable, ReplaySubject, withLatestFrom} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {Environment} from '../model/environment';
import {NotificationService} from './notification.service';
import {RegistrationView} from '../model/registration-view';
import {Patron, PATRON_PHONE_TYPE} from '../model/patron/patron';
import {CommunicationPreferences} from '../model/patron/communication-preferences';
import {RegistrationNotifications} from '../model/patron/registration-notifications';
import {PatronUpdatedAction} from '../core/shared/store/global.actions';
import {GlobalState} from '../core/shared/store/global.interfaces';
import {Store} from '@ngrx/store';
import {ServiceOptions, ServiceUtilService} from './service-util.service';
import {LoginComponent} from '../core/login/login.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ResponseBase} from "../model/response-base/response-base";

@Injectable({providedIn: 'root'})
export class PatronService {

  public patron$$ = new ReplaySubject<Patron>(1);
  public patron$ = this.patron$$.asObservable();
  public patron: Patron;

  constructor(
    private serviceUtil: ServiceUtilService,
    private store: Store<GlobalState>,
    private dialog: MatDialog,
    private environment: Environment,
    private http: HttpClient,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private notificationService: NotificationService
  ) {
    this.store.select((state) => state.app.patron)
      .subscribe(patron => {
        this.patron$$.next(patron);
        this.patron = patron;
      });
  }

  openLoginDialog(): void {
    if (this.patron) {
      return;
    }
    this.dialog.open(LoginComponent);
  }

  /**
   * Validate email hasn't been taken
   */
  checkForEmail(email: string): Observable<boolean> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/existing-email?email=${encodeURIComponent(email)}`;
    const result = this.serviceUtil.httpInvoker(this.http.get<boolean>(url));
    result.subscribe();
    return result;
  }

  getCommunicationPreferences(): Observable<CommunicationPreferences> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/communication-preferences`;
    const result = this.serviceUtil.httpInvoker(this.http.get<ResponseBase<CommunicationPreferences>>(url))
      .pipe(map(rb => rb.objects[0]));
    result.pipe(withLatestFrom(this.patron$))
      .subscribe(([comPref, patron]) => {
        const updatePatron = Object.assign(new Patron(), {
          ...patron,
          notificationEmail: comPref.notificationEmail,
          phone1: comPref.phoneNumber,
          unsubscribeAllNotifications: comPref.unsubscribeAll
        });

        this.updatePatronState(updatePatron);
      });
    return result;
  }

  hasCommunicationPreferences(): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/communication-preferences/valid`;
    const result = this.serviceUtil.httpInvoker(this.http.get<ResponseBase<boolean>>(url), {
      errorMessage: {},
      returnOnError: {objects: [false]},
    })
      .pipe(map(rb => rb.objects[0]));
    result.subscribe();
    return result;
  }

  /**
   * Returns the currently logged in patron according to the server
   */
  getPatron(options?: ServiceOptions<ResponseBase<Patron>>): Observable<Patron> {
    const url = `${this.environment.apiUrl}/patrons/v2/patron`;
    const result = this.serviceUtil.httpInvoker(this.http.get<ResponseBase<Patron>>(url), options)
      .pipe(map(rb => rb.objects[0]));
    result.subscribe(patron => this.updatePatronState(patron));
    return result;
  }

  save(patron: Patron, options?: ServiceOptions<ResponseBase<Patron>>): Observable<Patron> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons`;
    const result = this.serviceUtil.httpInvoker(this.http.put<ResponseBase<Patron>>(url, patron), options)
      .pipe(map(rb => rb.objects[0]));
    result.subscribe(patron => this.updatePatronState(patron));
    return result;
  }

  // this should ultimately be private
  updatePatronState(patron: Patron): void {
    this.store.dispatch(new PatronUpdatedAction(patron));
  }

  saveRegistrationPersonalInfo(registrationView: RegistrationView, options?: ServiceOptions<Patron>): Observable<Patron> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register`;
    const result = this.serviceUtil.httpInvoker(this.http.post<Patron>(url, JSON.stringify(registrationView)), options);
    result.subscribe(patron => this.updatePatronState(patron));
    return result
  }

  updatePin(pin: string, options?: ServiceOptions<boolean>): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/account/pin`;
    const result = this.serviceUtil.httpInvoker(this.http.patch<boolean>(url, pin), options);
    result.subscribe()
    return result;
  }

  /**
   * Patron self renew expired account in good standing
   */
  renewAccount(options?: ServiceOptions<void>): Observable<void> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/account/renew`;
    const result = this.serviceUtil.httpInvoker(this.http.patch<void>(url, ''), options);
    result.subscribe(() => this.getPatron()); // to update patron w/ new status + expiration instead of making assumptions about them in component subscription
    return result;
  }

  updateCommunicationPreferences(preferences: CommunicationPreferences, options?: ServiceOptions<ResponseBase<CommunicationPreferences>>): void {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/communication-preferences`;
    this.serviceUtil.httpInvoker(this.http.put<ResponseBase<CommunicationPreferences>>(url, JSON.stringify(preferences)), options)
      .pipe(
        map(rb => rb.objects[0]),
        withLatestFrom(this.patron$))
      .subscribe(([comPref, patron]) => {
        const updatePatron = Object.assign(new Patron(), {
          ...patron,
          notificationEmail: comPref.notificationEmail,
          phone1: comPref.phoneNumber,
          unsubscribeAllNotifications: comPref.unsubscribeAll
        });

        this.updatePatronState(updatePatron);
      });
  }

  registrationSelectNotifications(notification: RegistrationNotifications, options?: ServiceOptions<ResponseBase<RegistrationNotifications>>): Observable<RegistrationNotifications> {
    const url = `${this.environment.apiUrl}/v2/patrons/registration/notifications/select`;
    const result = this.serviceUtil.httpInvoker(this.http.put<ResponseBase<RegistrationNotifications>>(url, JSON.stringify(notification)), options)
      .pipe(map(rb => rb.objects[0]));
    result
      .pipe(withLatestFrom(this.patron$))
      .subscribe(([r, patron]) => {
        const updatePatron = Object.assign(new Patron(), {
          ...patron,
          phone1: r.phone,
          phone1Type: r.phoneType as PATRON_PHONE_TYPE,
        });
        this.updatePatronState(updatePatron);
      });
    return result;
  }

  /**
   * this doesn't appear to exist on the api at present but was still referenced in components (AccountComponent)?
   */
  // deleteAccount(): Observable<Patron> {
  //   const url = `${this.environment.apiUrl}/v1/patrons/account`;
  //   return this.http.delete(url).pipe(
  //     catchError(() => {
  //       this.notificationService.showSnackbarError('Unable to delete account. Please try again later.');
  //       return EMPTY;
  //     })
  //   );
  // }

  /* ************* UNTOUCHED IN  REFACTOR  ************** */

  /**
   * Returns true if patron is under the balance limit for the account type, false otherwise
   */
  underBalanceLimit(): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/account/under-limit`;

    return this.http.get<boolean>(url).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.notificationService.showSnackbarError('Error retrieving account information. Please contact customer support.');
        }
        return EMPTY;
      })
    );
  }

  /**
   * Returns the currently logged in patron according to the server INCLUDING:
   * active hold count, account balance, patron rule, loan count, and claimed returned count
   * This will pull the statistics for this patron. This is pulled in a separate call to eliminate the overhead of pulling this with the patron record; this
   * should only be called when this data is actually needed - ie. on the patron details page.
   */
  getPatronWithStats(): Observable<Patron> {
    const url = `${this.environment.apiUrl}/patrons/v1/patron?includeStats=true`;
    return this.http.get<Patron>(url);
  }

}
