import {Observable, of as observableOf} from 'rxjs';
import {AbstractControl, AsyncValidatorFn, ValidationErrors,} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';
import {PatronService} from '../services/patron.service';

export function verifyUniquePatronEmail(organizationId: number, patronService: PatronService, currentEmail: string): AsyncValidatorFn {
  return (
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (control.value === currentEmail) {
      return observableOf(null);
    }
    return patronService.checkForEmail(control.value).pipe(
      map((emailAvailable) => {
        return !emailAvailable ? {emailTaken: true} : null;
      }),
      catchError(() => {
        return null;
      })
    );
  };
}
