import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Checkout, CirculationItemClaimedReturned, Environment, NotificationService, ResponseBase} from '@raven';

@Injectable({providedIn: 'root'})
export class CheckoutService {

  constructor(private environment: Environment,
              private http: HttpClient,
              private notificationService: NotificationService) {
  }

  patronGetCheckouts(filter: string, page: number, limit: number): Observable<ResponseBase<Checkout>> {
    const url = `${this.environment.apiUrl}/patrons/v1/patrons/checkouts`;
    const params: { filter?, page?, limit?, sortBy?, sortDirection? } = {};
    filter && (params.filter = filter);
    (page && page - 1 >= 0) && (params.page = page);
    (limit && limit > 0) && (params.limit = limit);
    return this.http.get<ResponseBase<Checkout>>(url, {params}).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to fetch checkouts. Please try again later.');
        return EMPTY;
      })
    );
  }

  claimReturned(claimReturnedRequest: CirculationItemClaimedReturned): Observable<CirculationItemClaimedReturned> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/checkouts/claims`;
    return this.http.post<ResponseBase<CirculationItemClaimedReturned>>(url, JSON.stringify(claimReturnedRequest))
      .pipe(
        catchError(() => {
          this.notificationService.showSnackbarError('Unable to submit claim. Please try again later.');
          return EMPTY;
        }),
        map(rb => rb.objects[0])
      );
  }

  renewCheckout(checkoutId: number): Observable<Checkout> {
    const url = `${this.environment.apiUrl}/patrons/v2/checkouts/${checkoutId}/renew`;
    return this.http.patch<ResponseBase<Checkout>>(url, '').pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to renew item. Please try again later.');
        return EMPTY;
      }),
      map(rb => rb.objects[0])
    );
  }

  renewCheckoutList(checkoutIds: number[]): Observable<Checkout[]> {
    const url = `${this.environment.apiUrl}/patrons/v2/checkouts/renew`;
    return this.http.patch<ResponseBase<Checkout[]>>(url, checkoutIds).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to renew items. Please try again later.');
        return EMPTY;
      }),
      map(rb => rb.objects[0])
    );
  }
}
