import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PatronItemRequest, RoutesService} from '@raven';

/**
 * DELETE ITEM REQUEST DIALOG COMPONENT
 */
@Component({
  selector: 'rn-delete-item-request-dialog',
  styleUrls: ['./delete-item-request-dialog.scss'],
  template: `
    <div class="flex-col container">
      <h3 class="header">
        Cancel Item Request{{ data.itemRequests.length | plural }}
      </h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="data.cantDelete > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ data.cantDelete }} of the selected items cannot be deleted.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
          <strong>{{data.itemRequests[0].title}}</strong>
        </span>
        <span class="Text-Style dialog-body">
                Canceling {{data.itemRequests.length > 1 ? 'these items' : 'this item'}}
          is not a reversible action. Once it is canceled {{data.itemRequests.length > 1 ? 'they' : 'it'}} cannot be recovered.
        </span>
        <span class="Text-Style dialog-body">
          <strong>
            Are you sure you want to cancel your request for this item?
          </strong>
        </span>
      </div>
      <hr class="divider"/>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">
          Go Back
        </button>
        <button mat-raised-button color="warn" class="save-button-group save-button" (click)="onDelete()">
          Yes, Cancel
        </button>
      </div>
    </div>
  `
})
export class DeleteItemRequestDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteItemRequestDialog>,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { itemRequests: PatronItemRequest[]; cantDelete: number }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onDelete(): void {
    this.dialogRef.close(true);
  }
}
