import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ApplePay, Payments, payments, TokenResult} from '@square/web-sdk';
import {BehaviorSubject} from 'rxjs';
import {NotificationService, PATRON_PAYMENT_METHOD, PatronCardPaymentMethod, PatronPaymentService} from '@raven';

@Component({
  selector: 'rn-payment-confirmation-dialog',
  templateUrl: './payment-confirmation-dialog.component.html',
  styleUrls: ['./payment-confirmation-dialog.component.scss'],
})
export class PaymentConfirmationDialogComponent implements OnInit {
  appId: string;
  locationId: string;
  loadingSubject = new BehaviorSubject<boolean>(true);
  sq: Payments;
  applePay: ApplePay;
  PATRON_PAYMENT_METHOD = PATRON_PAYMENT_METHOD

  constructor(
    private dialogRef: MatDialogRef<PaymentConfirmationDialogComponent>,
    private patronPaymentService: PatronPaymentService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      source: PatronCardPaymentMethod | string;
      paymentType: PATRON_PAYMENT_METHOD;
      savedCard: boolean;
      paymentAmount: string;
      token: string;
      sourceDisplayString: string;
      paymentDate: string;
      organizationName: string;
    }
  ) {
  }

  async ngOnInit() {
    if (this.data.paymentType == PATRON_PAYMENT_METHOD.APPLE) {
      await this.squareInit();
    }
  }

  async squareInit() {
    this.appId = this.patronPaymentService.getSquareAppId();
    this.locationId = this.patronPaymentService.getSquareLocationId();
    try {
      this.sq = await payments(this.appId, this.locationId);
      const paymentRequest = this.sq.paymentRequest({
        countryCode: 'US',
        currencyCode: 'USD',
        total: {amount: this.data.paymentAmount, label: 'Total'},
      });
      this.applePay = await this.sq.applePay(paymentRequest);
      this.loadingSubject.next(false);
    } catch (e) {
      console.error('Could not initialize Apple Pay', e);
      this.notificationService.showSnackbarError('Unable to initialize Apple Pay. Apple Pay requires a Mac or iOS device and the Safari web browser');
      this.dialogRef.close(false);
    }
  }

  async authorizeApplePay() {
    try {
      const tokenResult: TokenResult = await this.applePay.tokenize();
      this.data.sourceDisplayString = 'Apple Pay';
      this.data.token = this.tokenValid(tokenResult) ? tokenResult.token : null;
      if (this.data.token) {
        this.dialogRef.close(true);
        return;
      }
    } catch (e) {
      console.error('Apple Pay error ', e);
    }
    this.dialogRef.close(false);
  }

  tokenValid(tokenResult: TokenResult): boolean {
    if (tokenResult.errors) {
      console.error(tokenResult.errors);
    }
    return tokenResult.status === 'OK';
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  authorize(): void {
    this.dialogRef.close(true);
  }
}
