import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EMPTY, takeWhile} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {
  AuthService,
  Branch,
  BranchService,
  NotificationService,
  Organization,
  OrganizationService,
  passwordConfirmValidator,
  PatronService,
  PatronVerificationService,
  Registration,
  RegistrationService,
  TermsComponent,
  verifyUniquePatronEmail
} from '@raven';

@Component({
  selector: 'rn-registration-account-create',
  templateUrl: './registration-account-creation.component.html',
  styleUrls: ['./registration-account-creation.component.scss'],
})
export class RegistrationAccountCreationComponent implements OnInit {
  organization: Organization;
  selectLibraryForm: FormGroup;
  accountDataForm: FormGroup;
  isLoading: boolean;
  organizations: Organization[] = [];
  branches: Branch[];

  @ViewChild(MatStepper) matStepper: MatStepper;

  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService,
    private branchService: BranchService,
    private registrationService: RegistrationService,
    private patronService: PatronService,
    private patronVerificationService: PatronVerificationService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    // set loading
    this.isLoading = true;
    // init the form
    this.organizationService.organization$
      .pipe(
        takeWhile((organization: Organization) => {
          if (organization) {
            this.organization = organization;
            this.organizations = [organization];
            this.createForms();
            return false; // returning false to the takeWhile pipe unsubscribes, so we don't initialize forms multiple times
          }
          return true;
        })
      ).subscribe();
    // *** I don't like this being inline here.  It does 4 things
    // 1) fetch the branches from the service, which requires the component to know org_id at present
    // 2) mutate the components 'branches' field for use by the form select box
    // 3) set the dropdown starting value to the first branch returned (I haven't found a better way)
    // 4) clear the loading indicator
    // We could bind the branches to the UI asynchronously and skip the mutation, but setting the value
    //   has to be done in TypeScript like this, so we'd still need to get the relevant branchId out of the observable
    // I don't like this way of doing the loading indicator, but I don't have a better way yet
    this.branchService
      .getBranches()
      .pipe(
        tap((branches) => {
          this.branches = branches;
          if (this.branches.length > 0) {
            this.selectLibraryForm
              .get('branchId')
              .setValue(this.branches[0].id);
          }
        }),
        finalize(() => this.isLoading = false)
      )
      .subscribe();

    // check if patron has recently gone through registration and is
    // a patron pending, if so skip to the end of this form
    if (this.patronService.patron?.email) {
      setTimeout(() => this.jumpToEmailVerification(this.patronService.patron?.email), 0)
    }
  }

  jumpToEmailVerification(patronEmail: string): void {
    console.log('Patron is pending or needs to verify their email');
    // It's not immediately clear why we set a value in a form and then hide the form from the user
    // The reason: 'resendEmailVerification' gets the email value from this field
    this.accountDataForm.get('email').setValue(patronEmail);
    this.matStepper.steps.get(0).completed = true;
    this.matStepper.steps.get(1).completed = true;
    this.matStepper.selectedIndex = 2;
    this.matStepper.steps.get(0).editable = false;
    this.matStepper.steps.get(1).editable = false; // => (2)
  }

  createForms(): void {
    this.selectLibraryForm = this.fb.group(
      {
        organizationId: [
          this.organization.id,
          {validators: [Validators.required]},
        ],
        branchId: ['', {validators: [Validators.required]}],
      },
      {}
    );
    this.accountDataForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z0-9]{2,4}$")],
          verifyUniquePatronEmail(this.organization.id, this.patronService, ''),
        ],
        password: ['', {validators: [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'),]}],
        passwordConfirm: ['', {validators: [Validators.required]}],
        acceptance: ['', {validators: [Validators.required]}],
      },
      {
        validators: passwordConfirmValidator('password', 'passwordConfirm'),
      }
    );
  }

  termsAndConditions(): void {
    this.dialog.open(TermsComponent, {
      data: 'Terms and Conditions'
    });
  }

  privacyPolicy(): void {
    this.dialog.open(TermsComponent, {
      data: 'Privacy Policy'
    });
  }

  continue(): void {
    this.matStepper.selected.completed = true;
    this.matStepper.selectedIndex = 1;
  }

  register(): void {
    this.isLoading = true;
    const selectLibraryValue = this.selectLibraryForm.value;
    const accountDataValue = this.accountDataForm.value;
    // create the form
    const saveRegistration: Registration = {
      organizationId: selectLibraryValue.organizationId,
      branchId: selectLibraryValue.branchId,
      email: accountDataValue.email,
      password: accountDataValue.password,
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      phone: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      language: ''
    };
    this.registrationService.registerEmail(saveRegistration)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError((err: unknown) => {
          console.log('Error message', err)
          if (err instanceof HttpErrorResponse) {
            if (err.status == 409) {
              this.notificationService.showSnackbarError('Error: this account already exists');
            } else {
              this.notificationService.showSnackbarError('Error registering your account. Please contact customer support.');
            }
          }
          return EMPTY;
        }))
      .subscribe(() => {
        // this is asynchronous but we shouldn't need to wait, it will complete in the background
        this.authService.login(accountDataValue.email, accountDataValue.password);

        // set a success message
        this.notificationService.showSnackbarSuccess('Success! Your account has been created.');
        this.matStepper.selected.completed = true;
        this.matStepper.selectedIndex = 2;
        this.matStepper.steps.get(0).editable = false;
        this.matStepper.steps.get(1).editable = false;
      });
  }

  resendEmailVerification(): void {
    this.patronVerificationService
      .sendEmailVerificationRequest(
        this.organization.id,
        this.accountDataForm.value.email
      )
      .pipe(
        catchError(() => {
          const message =
            'Error sending verification code. Please contact customer support.';
          this.notificationService.showSnackbarError(message);
          return EMPTY;
        })
      )
      .subscribe(() => {
        // set a success message
        const message =
          'The verification code has been sent. It will expire in 30 minutes. Please check your email.';
        this.notificationService.showSnackbarSuccess(message);
      });
  }
}
