import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {EMPTY, Observable, share, Subject, switchMap, takeUntil} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import { NotificationService, PATRON_ITEM_REQUEST_STATUS, PatronItemRequest, PatronItemRequestKeys, PatronItemRequestService, SubmitRequestComponent} from '@raven';
import {DeleteItemRequestDialog} from '../dialogs/delete-item-request-dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

type TableKeys = PatronItemRequestKeys | 'actions' | 'mobile';

@Component({
  selector: 'rn-item-request-list',
  templateUrl: './item-request-list.component.html',
  styleUrls: ['./item-request-list.component.scss'],
})
export class ItemRequestListComponent implements OnInit {
  PATRON_ITEM_REQUEST_STATUS = PATRON_ITEM_REQUEST_STATUS;

  closed$: Observable<PatronItemRequest[]>;
  active$: Observable<PatronItemRequest[]>;
  activeDataSource = new MatTableDataSource<PatronItemRequest>();
  closedDataSource = new MatTableDataSource<PatronItemRequest>();

  destroy$ = new Subject<boolean>();
  isMobile = false;

  desktopColumns: TableKeys[] = ['created', 'title', 'author', 'status', 'actions'];
  mobileColumns: TableKeys[] = ['mobile','actions'];
  closedStatuses: PATRON_ITEM_REQUEST_STATUS[] = [
    PATRON_ITEM_REQUEST_STATUS.APPROVED,
    PATRON_ITEM_REQUEST_STATUS.CANCELED,
    PATRON_ITEM_REQUEST_STATUS.DECLINED
  ]
  columnsToDisplay = this.desktopColumns;

  @ViewChild('aSort') aSort: MatSort;
  @ViewChild('cSort') cSort: MatSort;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    private patronItemRequestService: PatronItemRequestService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.columnsToDisplay = this.mobileColumns;
        } else {
          this.isMobile = false;
          this.columnsToDisplay = this.desktopColumns;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getData(): void {
    const data$ = this.patronItemRequestService.getAllPatronRequests().pipe(share());
    this.active$ = data$.pipe(
      map(reqs => reqs.filter((r) => !this.closedStatuses.includes(r['status']))),
      tap(reqs => this.activeDataSource.data = reqs)
    );
    this.closed$ = data$.pipe(
      map(reqs => reqs.filter((r) => this.closedStatuses.includes(r['status']))),
      tap(reqs => this.closedDataSource.data = reqs)
    );
  }

  goToRequest(request: object) {
    this.router.navigate(['/item-request-details', request['id']]);
  }

  deleteItemRequest(request: PatronItemRequest): void {
    const dialogRef = this.dialog.open(DeleteItemRequestDialog, {
      data: {itemRequests: [request], cantDelete: 0},
      maxWidth: '95vw',
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap((deleteConfirmation: boolean) => {
          return deleteConfirmation ? this.patronItemRequestService.cancel(request.id) : EMPTY;
        })
      )
      .subscribe((success) => {
        if (success) {
          this.notificationService.showSnackbarSuccess('Request successfully canceled');
        }
        this.getData();
      });
  }

  handleSortChange(sortState: Sort) {
    this.activeDataSource.sort = this.aSort;
    this.closedDataSource.sort = this.cSort;
  }

  openItemRequestModal(): void {
    this.dialog.open(SubmitRequestComponent);
  }
}
