import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, Observable, Subject, switchMap} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {
  GlobalState,
  Organization,
  PATRON_PAYMENT_METHOD,
  PatronAccountStatus,
  PatronCardPaymentMethod,
  PatronLedgerService,
  PatronPaymentService,
  selectOrganization
} from '@raven';
import {PaymentConfirmationDialogComponent} from './payment-confirmation-dialog/payment-confirmation-dialog.component';
import {PaymentSetupDialogComponent} from './payment-setup-dialog/payment-setup-dialog.component';

@Component({
  selector: 'rn-make-a-payment-form',
  template: `
    <rn-expansion-panel [isCollapsed]="!startExpanded">
      <h2 expansionHeader>Make a Payment</h2>
      <form *ngIf="paymentMethods$ | async as paymentMethods" [formGroup]="makeAPaymentForm"
            class="makeAPayment flex-row-col-responsive flex-stretch-space-between" style="gap: 2vw">
        <div class="flex-col payment-source">
          <mat-label>Payment Source</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="paymentSource">
              <mat-option *ngFor="let option of paymentMethods" [value]="option">{{ option.cardBrand | titlecase }}
                **** {{ option.last4 }}</mat-option>
              <mat-option [value]="PATRON_PAYMENT_METHOD.GPAY">
                Google Pay
              </mat-option>
              <mat-option [value]="PATRON_PAYMENT_METHOD.APPLE" *ngIf="canApplePay">
                Apple Pay
              </mat-option>
              <mat-option [value]="PATRON_PAYMENT_METHOD.SQUARE">
                Manually enter a payment card
              </mat-option>
            </mat-select>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentSource']?.hasError('required')">
              Payment Source is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-col payment-amount">
          <mat-label>Payment Amount</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="number" min="0" formControlName="paymentAmount"/>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentAmount']?.hasError('required')">
              Payment Amount is required.
            </mat-error>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentAmount']?.hasError('max')">
              Amount cannot be greater than Total Amount Due.
            </mat-error>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentAmount']?.hasError('min')">
              Amount must be greater than 0.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-col payment-date">
          <mat-label>Payment Date</mat-label>
          <mat-form-field appearance="outline">
            <input matInput #date_input formControlName="paymentDate" placeholder="Payment Date" [matDatepicker]="$any(picker)" [min]="today()"/>
            <mat-datepicker-toggle disabled matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentDate']?.hasError('required')">
              Payment Date is required.
            </mat-error>
            <mat-error *ngIf="makeAPaymentForm.controls['paymentDate'].hasError('matDatepickerMin')">
              Payment date cannot be in the past.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-col payment-submit">
          <button mat-flat-button color="primary" class="make-payment-button" [disabled]="!this.makeAPaymentForm.valid || this.makeAPaymentForm.pristine"
                  (click)="submit()">
            Submit&nbsp;Payment
          </button>
        </div>
      </form>
    </rn-expansion-panel>
  `,
  styles: [`
    .make-payment-button {
      height: 50px;
    }

    @media screen and (max-width: 599px) {
      .make-payment-button {
        margin-bottom: 16px;
      }
    }

    @media screen and (min-width: 600px) {
      .make-payment-button {
        margin-top: 22px;
      }
    }

    .payment-source,
    .payment-amount,
    .payment-date,
    .payment-submit, {
      flex: 1 1 100%;
    }

    @media screen and (min-width: 960px) {
      .payment-source,
      .payment-date,
      .payment-submit, {
        max-width: 23.5%;
      }
      .payment-amount {
        flex: 1 5 100%;
        max-width: 23.5%;
      }
    }

    @media screen and (min-width: 600px) and (max-width: 959px) {
      .payment-source {
        max-width: 32%
      }
      .payment-amount {
        flex: 1 5 100%;
        max-width: 20%;
      }
      .payment-date,
      .payment-submit {
        max-width: 20%;
      }
    }
  `],
})
export class MakeAPaymentFormComponent implements OnInit, OnDestroy {
  @Input() startExpanded = true;
  @Input() patronAccountStatus: PatronAccountStatus;
  @Output() paymentCreated = new EventEmitter();
  makeAPaymentForm: FormGroup;
  paymentMethods$: Observable<PatronCardPaymentMethod[]>;
  firstSource: any = '';
  organization: Organization = {};
  PATRON_PAYMENT_METHOD = PATRON_PAYMENT_METHOD;
  destroy$ = new Subject<boolean>();
  canApplePay = false;

  constructor(
    protected patronLedgerService: PatronLedgerService,
    private patronPaymentService: PatronPaymentService,
    private store: Store<GlobalState>,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    if (typeof ApplePaySession !== 'undefined') {
      try {
        this.canApplePay = ApplePaySession.canMakePayments();
      } catch (e) {
        console.log('Apple Pay unavailable');
      }
    }
    this.paymentMethods$ = this.patronPaymentService.getAllPaymentMethods()
      .pipe(tap(methods => {
        if (methods && methods.length > 0) {
          this.firstSource = methods[0];
        }
        this.makeAPaymentForm = this.initMakeAPaymentForm(this.fb);
        if (this.patronAccountStatus) {
          this.makeAPaymentForm.get('paymentAmount').addValidators([Validators.max(this.patronAccountStatus.currentAccountBalance)]);
        }
      }));

    this.store.select(selectOrganization)
      .pipe(take(1))
      .subscribe((organization) => {
        this.organization = organization;
      });
  }

  initMakeAPaymentForm(fb: FormBuilder): FormGroup {
    const group = fb.group({
      paymentSource: [this.firstSource, {validators: [Validators.required]}],
      paymentAmount: ['', {validators: [Validators.required, Validators.min(0.01),]}],
      paymentDate: [{value: this.today(), disabled: true}, {validators: [Validators.required]}],
    });
    return group;
  }

  today(): Date {
    return new Date();
  }

  async submit() {
    const amount = this.makeAPaymentForm.value.paymentAmount as number;
    const date = this.makeAPaymentForm.get('paymentDate').value;
    const source = this.makeAPaymentForm.value.paymentSource;

    this.makeAPaymentForm.updateValueAndValidity()
    if (!this.makeAPaymentForm.valid) {
      return;
    }

    let paymentType: PATRON_PAYMENT_METHOD;
    let savedCard = false;
    if (source instanceof Object) {
      paymentType = PATRON_PAYMENT_METHOD.SQUARE;
      savedCard = true;
    } else if (source instanceof String || typeof source === 'string') {
      paymentType = source as PATRON_PAYMENT_METHOD;
    } else {
      // unknown payment type
      return;
    }

    const dialogData = {
      source: source,
      paymentType: paymentType,
      savedCard: savedCard,
      paymentAmount: amount.toFixed(2),
      token: '', //gets set by PaymentSetupDialog depending on payment type
      sourceDisplayString: '',
      paymentDate: date,
      organizationName: this.organization.name,
    };

    const setupDialogRef = this.dialog.open(PaymentSetupDialogComponent, {
      data: dialogData,
      maxWidth: '95vw'
    });
    setupDialogRef.afterClosed()
      .pipe(
        filter(data => !!data && !!data.token),
        switchMap(data => {
          return this.dialog.open(PaymentConfirmationDialogComponent, {
            data: data,
            maxWidth: '95vw'
          }).afterClosed()
        }),
        take(1),
        filter(confirmation => !!confirmation && !!dialogData.token),
        switchMap(() => this.patronPaymentService.makePayment(paymentType, dialogData.token, amount, date)),
      )
      .subscribe(result => {
        if (result) {
          this.paymentCreated.emit(true);
          const paymentAmountControl = this.makeAPaymentForm.get('paymentAmount');
          paymentAmountControl.setValue('');
          paymentAmountControl.setErrors(null);
          this.makeAPaymentForm.markAsPristine();
          this.makeAPaymentForm.markAsUntouched();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
