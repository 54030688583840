import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Observable} from 'rxjs';
import {SubSink} from 'subsink';
import {AuthService, Branch, BranchService, OrganizationService, PatronService, RoutesService, TermsComponent} from '@raven';

@Component({
  selector: 'rn-catalog-footer',
  templateUrl: './catalog-footer.component.html',
  styleUrls: ['./catalog-footer.component.scss'],
})
export class CatalogFooterComponent implements OnInit {
  branch$: Observable<Branch> = null;
  patron$ = this.patronService.patron$;
  private subs = new SubSink();

  constructor(
    public routingService: RoutesService,
    public organizationService: OrganizationService,
    private dialog: MatDialog,
    private patronService: PatronService,
    private branchService: BranchService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.branch$ = this.branchService.currentPatronBranch$;
    }
  }

  termsAndConditions(): void {
    this.dialog.open(TermsComponent, {
      data: 'Terms and Conditions',
    });
  }

  privacyPolicy(): void {
    this.dialog.open(TermsComponent, {
      data: 'Privacy Policy',
    });
  }

  cookiesDialog(): void {
    this.dialog.open(TermsComponent, {
      data: 'Cookies Policy',
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
