import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DatePipe, Location as BrowserLocation} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, Patron, PATRON_PHONE_TYPE, PatronService, STATES} from '@raven';
import {getErrorMessage} from '../lib/field-error/field-error.helper';
import {CommonValidators} from '../lib/common-validators/common.validators';

@Component({
  selector: 'rn-registration-personal-info',
  templateUrl: './registration-personal-info.component.html',
  styleUrls: ['./registration-personal-info.component.scss'],
})
export class RegistrationPersonalInfoComponent implements OnInit {
  patron: Patron;
  PATRON_PHONE_TYPE = PATRON_PHONE_TYPE;
  personalInfoForm: FormGroup;
  isLoading: boolean;
  formStep = 'personalInfo';
  STATES = STATES;
  getErrorMessage = getErrorMessage;

  constructor(private authService: AuthService,
              private patronService: PatronService,
              private router: Router,
              private browserLocation: BrowserLocation,
              private datePipe: DatePipe,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    // set loading
    this.isLoading = true;
    this.createRegistrationForm();
    this.isLoading = false;
  }

  createRegistrationForm(): void {
    // clone the patron as the auth patron is readonly
    this.patron = JSON.parse(JSON.stringify(this.patronService.patron));
    if (!this.patron.addresses || this.patron.addresses.length == 0) {
      this.patron.addresses = [
        {
          address: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
        },
      ];
    }

    this.personalInfoForm = this.fb.group({
      firstName: [this.patron.firstName, {validators: [Validators.required]}],
      lastName: [this.patron.lastName, {validators: [Validators.required]}],
      dateOfBirth: [this.patron.dateOfBirth, {validators: [Validators.required]},],
      address: [this.patron.address, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]},],
      address2: [this.patron.address2, Validators.maxLength(100)],
      city: [this.patron.city, {validators: [Validators.required, Validators.minLength(3), Validators.maxLength(20)]},],
      state: [this.patron.state, {validators: [Validators.required]},],
      zipCode: [this.patron.zipCode, {validators: [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]},],
      phoneNumber: [this.patron.phone1, [Validators.required, CommonValidators.phoneNumber]],
      phoneType: [this.patron.phone1Type, {validators: [Validators.required]},],
    });
    this.personalInfoForm.get('phoneNumber').valueChanges.subscribe(phone => {
      // get only the numeric characters
      phone = phone.replace(/\D/g, '');
      // the formcontrol.valueChanges fires before the values on the form object update, allowing us to get the previous value
      const oldValue = this.personalInfoForm.value.phoneNumber?.replace(/\D/g, '');
      if (phone.length != 10 && phone == oldValue) {
        // this allows the user to backspace without automatically re-adding characters
        return;
      }
      // format to (###) ###-####
      let newPhone = `(${phone.slice(0, 3)}`;
      if (phone.length > 2) {
        newPhone += `) ${phone.slice(3, 6)}`;
      }
      if (phone.length > 5) {
        newPhone += `-${phone.slice(6, 10)}`;
      }
      this.personalInfoForm.get('phoneNumber').setValue(newPhone, {emitEvent: false});
      console.log(this.personalInfoForm.get('phoneNumber'))
    });

    this.personalInfoForm.controls['address'].setValue(this.patron.address, {emitEvent: true});
    this.personalInfoForm.controls['address2'].setValue(this.patron.address2, {emitEvent: true});
    this.personalInfoForm.controls['city'].setValue(this.patron.city, {emitEvent: true});
    this.personalInfoForm.controls['state'].setValue(this.patron.state, {emitEvent: true});
    this.personalInfoForm.controls['zipCode'].setValue(this.patron.zipCode, {emitEvent: true});
  }

  isPersonalInfoValid(): boolean {
    return (
      this.personalInfoForm.get('firstName').valid &&
      this.personalInfoForm.get('lastName').valid &&
      this.personalInfoForm.get('dateOfBirth').valid &&
      this.personalInfoForm.get('phoneNumber').valid &&
      this.personalInfoForm.get('phoneType').valid
    );
  }

  isAddressValid(): boolean {
    return (
      this.personalInfoForm.get('address').valid &&
      this.personalInfoForm.get('address2').valid &&
      this.personalInfoForm.get('city').valid &&
      this.personalInfoForm.get('state').valid &&
      this.personalInfoForm.get('zipCode').valid
    );
  }

  continue(): void {
    if (this.isPersonalInfoValid()) {
      this.formStep = 'address';
    }
  }

  submit(): void {
    const formModel = this.personalInfoForm.value;
    this.patron.firstName = formModel.firstName;
    this.patron.lastName = formModel.lastName;
    this.patron.dateOfBirth = formModel.dateOfBirth;
    this.patron.phone1 = formModel.phoneNumber;
    this.patron.phone1Type = formModel.phoneType;
    this.patron.address = formModel.address;
    this.patron.address2 = formModel.address2;
    this.patron.city = formModel.city;
    this.patron.state = formModel.state;
    this.patron.zipCode = formModel.zipCode;

    this.patronService
      .saveRegistrationPersonalInfo({
          firstName: formModel.firstName,
          lastName: formModel.lastName,
          dateOfBirth: this.datePipe.transform(formModel.dateOfBirth, 'yyyy-MM-dd'),
          phone: formModel.phoneNumber,
          // phone1Type: formModel.phoneType,
          address: formModel.address,
          address2: formModel.address2,
          city: formModel.city,
          state: formModel.state,
          zipCode: formModel.zipCode,
        },
        {
          successMessage: 'Personal information updated.',
          errorMessage: 'Error updating your personal information. Please contact customer support.',
          onSuccess: () => this.router.navigate(['/register/notifications'])
        });

  }

  cancel(): void {
    // head back to whence we came
    this.browserLocation.back();
  }

  random(length): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  today(): Date {
    return new Date();
  }
}
