import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Branch, BranchService, NotificationService, OrganizationService, Patron, PatronItemRequestService, PatronService} from '@raven';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'rn-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.scss'],
})
export class SubmitRequestComponent implements OnInit {
  requestForm: FormGroup;
  patron: Patron;
  branches$: Observable<Branch[]>;

  constructor(
    public organizationService: OrganizationService,
    private dialogRef: MatDialogRef<SubmitRequestComponent>,
    private patronItemRequestService: PatronItemRequestService,
    private patronService: PatronService,
    private branchService: BranchService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.patron = patronService.patron;
  }

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      itemTitle: ['', {validators: [Validators.required]}],
      author: ['', {validators: [Validators.required]}],
      isbn: ['', {validators: []}],
      pickupLocation: [null, {validators: [Validators.required]}],
      materialTypeDesc: ['', {validators: []}],
      notes: ['', {validators: []}],
      agreeToPolicies: [false, {validators: [Validators.requiredTrue]}],
    });
    this.branches$ = this.branchService.getAllBranches();
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  submit(): void {
    const v = this.requestForm.value;
    this.patronItemRequestService.submitItemRequest(
      v.itemTitle,
      v.author,
      v.pickupLocation,
      v.notes,
      v.isbn,
      v.materialTypeDesc
    ).subscribe((_) => {
      this.notificationService.showSnackbarSuccess('Request submitted successfully.');
      this.router.navigate(['/patron-item-requests-v2']);
    });
    console.log('submit');
  }
}
