import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfirmationDialogComponent, FavoriteService, ListDataService} from '@raven';

@Component({
  selector: 'rn-favorites',
  providers: [ListDataService],
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit {
  displayMode$: Observable<string>;

  constructor(public listDataService: ListDataService,
              private router: Router,
              private route: ActivatedRoute,
              private favoriteService: FavoriteService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.listDataService.pageEvent({page: 0, limit: (+this.route.snapshot.queryParams['pageSize']) || 25});
    this.listDataService.setFetchData(({filter, sort, page}) => {
      return this.favoriteService.getFavorites(page?.page, page?.limit);
    });
    this.displayMode$ = this.getDisplayMode();
  }

  getDisplayMode(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => {
        const modes = {
          grid: 'grid',
          list: 'list',
        };
        return modes[params['displayMode']] || 'grid';
      })
    );
  }

  // TODO figure out how this interacts with the back button and other parameters
  setDisplayMode(mode) {
    this.updateQueryParam('displayMode', mode);
  }

  updateQueryParam(paramName, value): void {
    const params = {};
    params[paramName] = value;
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge', // don't clobber OTHER params
      replaceUrl: true, // don't clog the history, replace the current entry
    });
  }

  confirm(config: object, callback: () => void): void {
    this.dialog
      .open(ConfirmationDialogComponent, {data: config})
      .afterClosed()
      .pipe(
        tap((confirm) => {
          console.log(confirm);
          if (confirm) {
            callback();
          }
        })
      )
      .subscribe(); // MatDialog completes itself, so no unsubscribe needed
  }

  clearFavorites(): void {
    if (this.listDataService.totalCount <= 0) {
      return;
    }
    this.confirm(
      {
        title: `Favorites (${this.listDataService.totalCount})`,
        message: 'Are you sure you want to clear all of your favorited items?',
        confirmButton: 'Yes, Clear',
      },
      () => {
        this.favoriteService.deleteAllFavorites().subscribe(() => {
          this.listDataService.triggerRefresh();
        });
      }
    );
  }
}
