import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {BehaviorSubject, forkJoin} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {AuthService, CarouselDisplay, CatalogRecord, CatalogRecordService, Environment} from '@raven';

export enum CATALOG_CATAGORIES {
  BIOGRAPHY = 'Biography',
  BUSINESS = 'Business',
  CHILDREN = 'Children',
  FANTASY = 'Fantasy',
  FICTION = 'Fiction',
  HISTORY = 'History',
  LITERATURE = 'Literature',
  MYSTERY = 'Mystery',
  NONFICTION = 'Nonfiction',
  RELIGION = 'Religion',
  ROMANCE = 'Romance',
  SCIFI = 'Science Fiction',
  SUSPENSE = 'Suspense',
  TECHNOLOGY = 'Technology',
  THRILLER = 'Thriller',
  TRAVEL = 'Travel',
  YOUNGADULT = 'Young Adult',
}

@Component({
  selector: 'rn-catalog-home',
  templateUrl: './catalog-home.component.html',
  styleUrls: ['./catalog-home.component.scss'],
})
export class CatalogHomeComponent implements OnInit {
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  CATALOG_CATAGORIES = CATALOG_CATAGORIES;
  popularTitles: CatalogRecord[] = null;
  recentlyAdded: CatalogRecord[] = null;
  booksOnOrder: CatalogRecord[] = null;
  popularAuthors: string[] = null;
  searchForm: FormGroup;
  carouselDisplays: CarouselDisplay[] = null;

  popularSubjects: { subject: string; catalogRecord: CatalogRecord }[] = [];

  constructor(private environment: Environment,
              private fb: FormBuilder,
              private router: Router,
              public authService: AuthService,
              public dialog: MatDialog,
              public catalogRecordService: CatalogRecordService) {
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.searchForm = this.fb.group({
      searchType: ['keyword', {validators: []}],
      searchTerm: ['', {validators: []}],
    });
    /*
        Most of the api calls here are placeholders, setting all the books in one call to lighten the load on our googleApi quota
         */
    forkJoin([
      this.catalogRecordService.getPopularTitles().pipe(
        tap((results) => {
          this.popularTitles = results.objects;
          this.setupMockPopularSubjects();
        })
      ),
      this.catalogRecordService.getCarouselDisplays().pipe(tap((result) => {
        this.carouselDisplays = result;
      })),
      this.catalogRecordService
        .getPopularAuthors()
        .pipe(
          tap((results) => {
            this.popularAuthors = results.objects;
          })
        ),
    ])
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe();
  }

  doSearch(): void {
    this.router.navigate([`/catalog-browse`],
      {queryParams: {'searchType': this.searchForm.value.searchType, 'query': this.searchForm.value.searchTerm},});
  }

  setupMockPopularSubjects(): void {
    const mockSubjectStrings = [
      'Self-Help',
      'Hardcover Non-Fiction',
      'Children\'s Books',
      'Business and Money',
      'Romance',
      'Mystery, Thriller & Suspense',
      'Politics & Social Sciences',
      'Literature & Fiction',
      'Action & Adventure',
      'Crafts & Hobbies',
      'Historical Fiction',
      'Autobiographies',
      'Home & Garden',
      'Short Stories',
      'Sports & Leisure',
      'Science Fiction',
      'Technology',
      'Libraries',
    ];
    for (let i = 0; i < mockSubjectStrings.length; i++) {
      this.popularSubjects.push({
        subject: mockSubjectStrings[i],
        catalogRecord: this.popularTitles[i % this.popularTitles.length],
      });
    }
  }
}
