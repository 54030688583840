import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {AngularFireAuthGuard} from "@angular/fire/compat/auth-guard";
import {AuthService} from "@raven";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(
    private angularFireAuthGuard: AngularFireAuthGuard,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  // is the user authenticated?  Then true
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthenticated()) return true;

    console.log('AuthGuard caching route: ', route);
    this.authService.redirectUrl = window.location.pathname;
    return this.router.createUrlTree(['/login']);
  }
}
