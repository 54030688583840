import {AbstractControl} from '@angular/forms';

export const getErrorMessage = function (fieldName: string, field: AbstractControl): string {
  if (!field) {
    return '';
  }

  if (this.field.hasError('mustBeTenDigitPhoneNumber')) {
    return `${capitalize(fieldName)} must be a 10-digit number.`;
  }

  if (this.field.hasError('mustBeValidPhoneNumber')) {
    return `${capitalize(fieldName)} is invalid. Please use a 10-digit number with a valid area code and exchange.`;
  }

  if (field.hasError('required')) {
    return `Please enter your ${fieldName}.`;
  }
  if (field.hasError('maxlength')) {
    return `${capitalize(fieldName)} is too long. Please use no more than ${field.getError('maxlength').requiredLength} characters.`;
  }
  if (field.hasError('minlength')) {
    return `${capitalize(fieldName)} is too short. Please use at least ${field.getError('minlength').requiredLength} characters.`;
  }
  if (fieldName == 'zip code') {
    return `${capitalize(fieldName)} is invalid. Please enter 5 digit zip code (+4 digits optional).`;
  }
  // we don't want to get here, this doesn't tell the user how to fix the error
  return `Please enter a valid ${fieldName}.`;
}

export const capitalize = function (str: string): string {
  if (!str) {
    return '';
  }
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}
