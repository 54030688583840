<div class="page-body-wrapper" [rvIsLoading]="listDataService.loading">
  <rn-account-status-warning context="banner"></rn-account-status-warning>
  <div class="flex-row flex-center-space-between">
    <div class="mat-display-1">On Hold <span class="hold-count">({{ listDataService.totalCount }})</span></div>
    <rn-hold-status-filter></rn-hold-status-filter>
  </div>
  <div class="select-all">
    <mat-checkbox
      (change)="$event ? toggleAll() : null"
      [checked]="selection.hasValue() && allSelected()"
      [indeterminate]="selection.hasValue() && !allSelected()">
      Select All
    </mat-checkbox>
  </div>
  <table mat-table class="non-mobile-table" aria-label="Holds" [dataSource]="listDataService.data">
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let hold" (click)="$event.stopPropagation()">
        <mat-checkbox
          (change)="$event ? selection.toggle(hold) : null"
          [checked]="selection.isSelected(hold)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let hold">
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{hold: hold}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef scope="col">Title</th>
      <td mat-cell *matCellDef="let hold" class="table-row-title pointer">
      <span (click)="routingService.goToCatalogRecordDetail(hold.catalogRecordId)" class="pointer">
        {{ hold.itemTitle }}
      </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="materialType">
      <th mat-header-cell *matHeaderCellDef scope="col">Material Type</th>
      <td mat-cell *matCellDef="let hold" class="pointer">
        {{ hold.materialType?.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef scope="col">Pickup Location</th>
      <td mat-cell *matCellDef="let hold" class="pointer">
        <ng-container *ngIf="hold.pickupBranchAddress; else noBranchLink">
          <a [href]="getBranchDirectionsURI(hold)" target="_blank" (click)="$event.stopPropagation()"
             rel="noopener" class="directions-link">
            {{ hold.pickupBranchName }}
          </a>
        </ng-container>
        <ng-template #noBranchLink>
          <span>{{ hold.pickupBranchName }}</span>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
      <td mat-cell *matCellDef="let hold" class="pointer">
        <rn-hold-status [hold]="hold"></rn-hold-status>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col">Actions</th>
      <td mat-cell *matCellDef="let hold" (click)="$event.stopPropagation()">
        <mat-icon *ngIf="isCancellableStatus(hold)" class="pointer" style="margin-right: 8px"
                  (click)="cancelHolds([hold])" matTooltip="Cancel" matTooltipPosition="above">
          highlight_off
        </mat-icon>
        <mat-icon *ngIf="isDeletableStatus(hold)" class="pointer" style="margin-right: 8px"
                  (click)="deleteHolds([hold])" matTooltip="Delete" matTooltipPosition="above">
          delete_outline
        </mat-icon>
        <mat-icon *ngIf="isRequestedStatus(hold)" class="pointer"
                  (click)="pauseHolds([hold])" matTooltip="Pause" matTooltipPosition="above">
          pause_circle_outline
        </mat-icon>
        <mat-icon *ngIf="isPausedStatus(hold)" class="pointer"
                  (click)="unpauseHolds([hold])" matTooltip="Unpause" matTooltipPosition="above">
          play_circle_outline
        </mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="mx-bold-underline"></tr>
    <tr mat-row *matRowDef="let hold; columns: columnsToDisplay"
        (click)="routingService.goToCatalogRecordDetail(hold.catalogRecordId)" class="mx-taller-row">
    </tr>
  </table>
  <div *ngIf="listDataService.totalCount == 0 && !(listDataService.filter | async)" class="empty-list">
    No items on hold.
  </div>
  <div *ngIf="listDataService.totalCount == 0 && (listDataService.filter | async)" class="empty-list">
    No holds match your current filters.
  </div>
  <rn-pager></rn-pager>
  <div class="clear-for-footer"></div>
  <div class="save-footer" style="background-color: white">
    <div class="save-footer-content flex-row flex-center-end">
      <div class="spacer"></div>
      <div class="button-row-right">
        <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="mobileHoldButtonMenu" class="hide-gt-xs">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #mobileHoldButtonMenu="matMenu" [overlapTrigger]="false" class="hide-gt-xs">
          <button mat-menu-item
                  [disabled]="!selectionHasDeletableHolds()" (click)="deleteHolds(selection.selected)">
            Delete Holds
          </button>
          <button mat-menu-item
                  [disabled]="!selectionHasCancellableHolds()" (click)="cancelHolds(selection.selected)">
            Cancel Holds
          </button>
          <button mat-menu-item
                  [disabled]="!selectionHasUnpausableHolds()" (click)="unpauseHolds(selection.selected)">
            Unpause Holds
          </button>
        </mat-menu>
        <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel hide-xs"
                [disabled]="!selectionHasDeletableHolds()" (click)="deleteHolds(selection.selected)">
          Delete Holds
        </button>
        <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel hide-xs"
                [disabled]="!selectionHasCancellableHolds()" (click)="cancelHolds(selection.selected)">
          Cancel Holds
        </button>
        <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel hide-xs"
                [disabled]="!selectionHasUnpausableHolds()" (click)="unpauseHolds(selection.selected)">
          Unpause Holds
        </button>
        <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                [disabled]="!selectionHasPausableHolds()" (click)="pauseHolds(selection.selected)">
          Pause Holds
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #mobileTableEntry let-hold="hold">
  <div class="mobile-table-entry flex-col">
    <a class="mobile-title-link">{{ hold.itemTitle }}</a>
    <span>Material Type: {{ hold.materialType?.description }}</span>
    <ng-container *ngIf="hold.pickupBranchAddress; else noBranchLinkMobile">
      <span>Pickup Location:
        <a [href]="getBranchDirectionsURI(hold)" target="_blank" (click)="$event.stopPropagation()" rel="noopener">
          {{ hold.pickupBranchName }}
        </a>
      </span>
    </ng-container>
    <ng-template #noBranchLinkMobile>
      <span>Pickup Location: {{ hold.pickupBranchName }}</span>
    </ng-template>
    <rn-hold-status [hold]="hold"></rn-hold-status>
  </div>
</ng-template>
