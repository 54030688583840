import * as GlobalActions from './global.actions';
import {BranchUpdatedAction, Global, MxConstants, OrganizationUpdatedAction, PatronUpdatedAction, TenantUpdatedAction} from '@raven';

export type Action = GlobalActions.All;

export function globalReducer(state: Global, action: Action): Global {
  switch (action.type) {
    case GlobalActions.DATA_LOAD: {
      return {...state};
    }
    case GlobalActions.BRANCH_UPDATED: {
      const payloadBranch = (action as BranchUpdatedAction).payload;
      // update the current state with the new account
      return {...state, branch: payloadBranch};
    }
    case GlobalActions.ORGANIZATION_UPDATED: {
      const payloadOrganization = (action as OrganizationUpdatedAction).payload;
      // update the current state with the new account
      return {...state, organization: payloadOrganization};
    }
    case GlobalActions.PATRON_UPDATED: {
      // update the current state with the new account
      return {...state, patron: (action as PatronUpdatedAction).payload};
    }
    case GlobalActions.ADMIN_PAGE_OFF: {
      return {...state, adminPage: false};
    }
    case GlobalActions.ADMIN_PAGE_ON: {
      return {...state, adminPage: true};
    }
    case GlobalActions.TENANT_UPDATED: {
      const payload = (action as TenantUpdatedAction).payload;
      // should update localStorage as well so this doesn't get clobbered
      localStorage.setItem(MxConstants.AUTH_TENANT, payload);
      // update the current state with the new tenant
      return {...state, tenant: payload};
    }
    default: {
      return state;
    }
  }
}

export const selectPatron = (state) => state.app.patron;
export const selectOrganization = (state) => state.app.organization;
