import {Component} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

/**
 * SAVE HISTORY DIALOG
 */
@Component({
  selector: 'mx-save-history-dialog',
  template: `
    <div class="dialog-wrapper flex-col">
      <h1 class="title">Save Reading History</h1>
      <h4>
        If you choose to save your reading history, you will be able to view the title, author and checkout date for all items you have checked out from the
        library. You may turn your Reading History ON or OFF any time when you login to your account. If you choose to activate your Reading History, and later
        turn it off, all stored records will be deleted from the system.
      </h4>
      <h4>
        Choosing to save your reading history is voluntary and constitutes your consent to the storage of information about items you check out. This
        information is subject to the judicial process.
      </h4>
      <div class="dialog-button-row">
        <button mat-raised-button color="primary" class="submit-button" (click)="onSubmit()">Ok</button>
      </div>
    </div>
  `,
  styles: [`
      .dialog-wrapper {
          width: 650px;
      }

      .title {
          font-family: var(--Mackinac), serif;
          font-weight: 500;
      }

      .submit-button {
          width: 192px;
          height: 50px;
      }
  `,
  ],
})
export class SaveHistoryDialogComponent {

  constructor(public dialogRef: MatDialogRef<SaveHistoryDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
