import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Hold, HoldService, RoutesService} from '@raven';
import {addDays} from 'date-fns'

/**
 * PAUSE HOLD DIALOG COMPONENT
 */
@Component({
  selector: 'rn-pause-hold-dialog',
  template: `
    <div class="hold-dialog flex-col">
      <h3>Pause Hold{{ pauseableHolds.length | plural }} ({{ pauseableHolds.length }})</h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="cantPause > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ cantPause }} of the selected items cannot be paused.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
          Are you sure you want to pause {{ pauseableHolds.length }} item hold{{ pauseableHolds.length | plural }}?
        </span>
        <mat-form-field appearance="outline" style="margin-left: 0; margin-bottom: 1em">
          <mat-label i18n="@@shared_pause_until_date">Pause Until Date</mat-label>
          <input #date_input matInput [min]="minDate" [matDatepicker]="$any(picker)" (dateInput)="onDateChange($event.value)" required/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [touchUi]="isMobile" disabled="false"></mat-datepicker>
        </mat-form-field>
        <div *ngIf="date_input.value && !isDate(pauseUntilDate)">
          <span class="Text-Style error">Date is invalid. Format: MM/DD/YYYY</span>
        </div>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">No</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" [disabled]="!isDate(pauseUntilDate)" (click)="onSubmit()">
          Yes, Pause
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      mat-form-field {
        width: 100%
      }

      .mat-form-field-appearance-legacy,
      .mat-form-field-suffix,
      .mat-datepicker-toggle-default-icon {
        width: 2em !important;
      }
    `,
  ],
})
export class PauseHoldDialog {
  minDate: Date = addDays(new Date(), 1);
  pauseUntilDate: Date = null;
  pauseableHolds: Hold[];
  cantPause: number;

  constructor(
    public dialogRef: MatDialogRef<PauseHoldDialog>,
    private breakpointObserver: BreakpointObserver,
    private holdService: HoldService,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { holds: Hold[], refreshHolds: () => void }
  ) {
    this.pauseableHolds = HoldService.getPauseableHolds(data.holds);
    this.cantPause = data.holds.length - this.pauseableHolds.length;
  }

  // Check if device is phone or tablet
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  onDateChange(inputDate: Date): void {
    this.pauseUntilDate = inputDate;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.pauseUntilDate || !this.isDate(this.pauseUntilDate)) {
      return;
    }
    this.holdService.pause(
      this.pauseableHolds.map((hold) => hold.id),
      this.pauseUntilDate,
      {onSuccess: this.onSuccess.bind(this)}
    );
  }

  onSuccess(result: boolean): void {
    if (result) {
      this.data.refreshHolds();
      this.dialogRef.close(true);
    }
  }

  isDate(thing: unknown): thing is Date {
    return typeof thing !== 'undefined' && Object.prototype.toString.call(thing) === '[object Date]';
  }
}
