import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {PATRON_NOTIFICATION_TITLES, PatronNotification} from "@raven";
import {PatronNotificationsService} from './patron-notifications.service';

@Component({
  selector: 'rn-notification-center',
  templateUrl: './patron-notification-center.component.html',
  styleUrls: ['./patron-notification-center.component.scss']
})
export class PatronNotificationCenterComponent implements OnInit {
  PATRON_NOTIFICATION_TITLES = PATRON_NOTIFICATION_TITLES;
  @ViewChild('notificationMenuTabGroup') notificationMenuTabGroup: MatTabGroup;
  notifications$: Observable<Array<PatronNotification>>;
  hasUnread$: Observable<boolean>;
  hasRead$: Observable<boolean>;

  constructor(
    private patronNotificationService: PatronNotificationsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.notifications$ = this.patronNotificationService.notifications$;
    this.hasUnread$ = this.patronNotificationService.hasUnread$;
    this.hasRead$ = this.patronNotificationService.hasRead$;
  }

  OnNotificationMenuOpened() {
    this.notificationMenuTabGroup.realignInkBar();
  }

  markAllRead(notifications: Array<PatronNotification>): void {
    const ids = notifications.filter(n => (!n.viewed)).map(n => n.id);
    if (ids.length > 0) {
      this.patronNotificationService.markViewed(ids).subscribe();
    }
  }

  markRead(notification: PatronNotification) {
    this.patronNotificationService.markViewed([notification.id]).subscribe();
  }

  softDeleteAll(notifications: Array<PatronNotification>): void {
    const ids = notifications.filter(n => n.viewed).map(n => n.id)
    if (ids.length > 0) {
      this.patronNotificationService.softDelete(ids).subscribe();
    }
  }

  softDelete(notification: PatronNotification) {
    this.patronNotificationService.softDelete([notification.id]).subscribe();
  }

}
