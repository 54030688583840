import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HoldsModule} from '../holds/holds.module';
import {SharedModule} from '../shared/shared.module';
import {SubmitRequestComponent} from '@raven';
import {MarcDetailsComponent} from '../catalog/catalog-item-details/marc-details/marc-details.component';
import {DeleteItemRequestDialog} from './dialogs/delete-item-request-dialog';
import {ItemDetailsComponent} from './item-details/item-details.component';
import {ItemRequestDetailsComponent} from './item-request-details/item-request-details.component';
import {ItemRequestListComponent} from './item-request-list/item-request-list.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [
    SubmitRequestComponent,
    ItemDetailsComponent,
    MarcDetailsComponent,
    ItemRequestDetailsComponent,
    ItemRequestListComponent,
    DeleteItemRequestDialog
  ],
  exports: [ItemDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    HoldsModule,
    CdkAccordionModule
  ],
})
export class ItemRequestsModule {
}
