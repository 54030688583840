import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {distinctUntilKeyChanged, filter, Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {Environment} from '../model/environment';
import {Branch} from '../model/branch';
import {catchError, map} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {PatronService} from './patron.service';
import {AuthService} from '../core/authentication/auth.service';
import {ResponseBase} from "../model/response-base/response-base";

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private currentPatronBranchSubject = new ReplaySubject<Branch>(1);
  currentPatronBranch$ = this.currentPatronBranchSubject.asObservable();
  currentPatronBranch: Branch = null;

  constructor(private environment: Environment,
              private notificationService: NotificationService,
              private patronService: PatronService,
              private authService: AuthService,
              private http: HttpClient) {
    this.patronService.patron$
      .pipe(
        filter(patron => patron && patron.id !== 0 && patron?.branchId != undefined),
        distinctUntilKeyChanged('branchId'),
        switchMap(patron => this.getById(patron.branchId)),
      )
      .subscribe(branch => {
        this.currentPatronBranch = branch;
        this.currentPatronBranchSubject.next(branch);
      })

  }

  getBranches(): Observable<Branch[]> {
    const url = `${this.environment.apiUrl}/public/v1/branches`;
    return this.http.get<Branch[]>(url);
  }

  getAllBranches(): Observable<Array<Branch>> {
    const url = `${this.environment.apiUrl}/patrons/v2/branches`;
    return this.http.get<ResponseBase<Branch>>(url)
      .pipe(map(rb => rb.objects));
  }

  getById(branchId: number): Observable<Branch> {
    const url = `${this.environment.apiUrl}/patrons/v2/branches/${branchId}`;

    if (!branchId) {
      return of(null);
    }

    return this.http.get<ResponseBase<Branch>>(url).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to load branch');
        return of(null);
      }),
      map(rb => rb.objects[0])
    );
  }
}
