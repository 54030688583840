import {AfterViewInit, Component, OnInit, Optional} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {AuthService, Environment, GlobalState, MxConstants, NotificationService} from '@raven';
import {ForgotPasswordDialogComponent} from './forgot-password-dialog.component';

@Component({
  selector: 'rn-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',]
})
export class LoginComponent implements OnInit, AfterViewInit {
  message: string;
  loginFailed: boolean;
  subscribers: { authSub: Subscription };
  isGoogleLoginVisible: boolean;
  loginForm: FormGroup;
  loginInProgress = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<LoginComponent>,
    public authService: AuthService,
    public angularFireAuth: AngularFireAuth,
    private environment: Environment,
    public router: Router,
    private fb: FormBuilder,
    private store: Store<GlobalState>,
    private notificationService: NotificationService,
    private dialog: MatDialog) {
    this.subscribers = {authSub: null};
    this.isGoogleLoginVisible = false;
  }

  ngOnInit(): void {
    // moved from index
    if (this.authService.isAuthenticated()) {
      console.log('Patron is logged in, sending to dashboard');
      // send them to the dashboard
      this.router.navigate(['dashboard']);
      return;
    }

    this.loginFailed = false;

    this.loginForm = this.fb.group(({
      email: ['', {validators: [Validators.email]}],
      password: ['', {validators: [Validators.required]}]
    }));
  }

  ngAfterViewInit() {
    if (this.authService.isAuthenticated()) {
      return;
    }
    // if the user gets into a weird state, this appears clear it out without disrupting normal login
    this.authService.logout();
    localStorage.removeItem(MxConstants.AUTH_TOKEN);
    localStorage.removeItem(MxConstants.AUTH_PATRON_OBJECT);
    localStorage.removeItem(MxConstants.AUTH_EXPIRES_AT);
    localStorage.removeItem(MxConstants.AUTH_ORGANIZATION_OBJECT);
    localStorage.removeItem(MxConstants.AUTH_BRANCH_OBJECT);
    Promise.all([
      firebase.auth().signOut(),
      this.angularFireAuth.signOut(),
    ]);
  }

  async login() {
    if (this.loginForm.invalid || this.loginInProgress) {
      return;
    }
    this.loginInProgress = true;
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const success = await this.authService.login(email, password);
    if (!success) {
      this.notificationService.showSnackbarError('Login failed.  Please try again.');
    } else {
      if (this.dialogRef) {
        this.dialogRef.close();
        window.location.reload();
      } else {
        this.authService.redirectUrl && console.log('Logging in, redirect to: ', this.authService.redirectUrl);
        await this.router.navigateByUrl(this.authService.redirectUrl || '/catalog');
      }
    }
    this.loginInProgress = false;
  }

  navRegister(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.router.navigateByUrl('/register');
  }

  forgotPassword(): void {
    const email = this.loginForm.get('email').valid ? this.loginForm.value.email : '';
    this.dialog.open(ForgotPasswordDialogComponent, {data: {email: email}, maxWidth: '90vw'});
  }

  async signInFailure(error): Promise<void> {
    console.log('signed FAILED in with error ', error);
    // end spinner
    this.isGoogleLoginVisible = false;
    await this.authService.logout();
  }

  onUiShown(event: any): void {
    console.log('the login ui is now showing', event);
    // start the loading spinner
    this.isGoogleLoginVisible = true;
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
