import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthService, Environment, NotificationService, PatronNotification, PatronService, ResponseBase} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class PatronNotificationsService {
  notificationsPipe = new BehaviorSubject(null);
  notifications$ = this.notificationsPipe.asObservable();
  hasUnread$ = new BehaviorSubject<boolean>(false);
  hasRead$ = new BehaviorSubject<boolean>(false);

  constructor(
    private environment: Environment,
    private http: HttpClient,
    private authService: AuthService,
    private patronService: PatronService,
    private notificationService: NotificationService
  ) {
    patronService.patron$
      .pipe(
        tap(patron => {
          if (!patron || patron.id === 0) {
            return;
          } // we're not logged in
          this.getPatronNotifications()
            .subscribe((notifications: PatronNotification[]) => {
              this.notificationsPipe.next(notifications);
              this.tapUnread(notifications);
              this.tapRead(notifications);
            });
        })
      ).subscribe();
  }

  tapUnread(notifications: PatronNotification[]): void {
    for (const notification of notifications) {
      if (!notification.viewed) {
        this.hasUnread$.next(true);
        return;
      }
    }
    this.hasUnread$.next(false);
  }

  tapRead(notifications: PatronNotification[]): void {
    for (const notification of notifications) {
      if (notification.viewed) {
        this.hasRead$.next(true);
        return;
      }
    }
    this.hasRead$.next(false);
  }

  /**
   * Raven Service - Get list of active Notifications for authenticated patron
   */
  getPatronNotifications(): Observable<PatronNotification[]> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/notifications`;
    return this.http.get<ResponseBase<PatronNotification>>(url)
      .pipe(map(rb => rb.objects));
  }

  /**
   * Raven Service - Mark notifications as viewed by Notification ID
   * @param notificationIds Notification ID(s) of the Notification record(s) to mark as viewed
   *
   * returns updated list of patron notifications
   */
  markViewed(notificationIds: number[]): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/notifications/mark-read`;

    return this.http.patch<ResponseBase<PatronNotification>>(url, notificationIds).pipe(
      map(rb => {
        const notifications = rb.objects;
        this.notificationsPipe.next(notifications);
        this.tapUnread(notifications);
        this.tapRead(notifications);
        return true;
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            // NOT_FOUND
            // No IDs given or notification record ID was not found in DB
            this.notificationService.showSnackbarError(
              'Could not update notifications, please refresh and try again.'
            );
          } else if (error.status === 401 || error.status === 403 || error.status === 417) {
            // FORBIDDEN
            // Patron is attempting to modify a notification record they do not own
            this.notificationService.showSnackbarError(
              'Notification record(s) not found or you are not the owner of the notification, please refresh and try again.'
            );
          }
        }
        return of(false);
      })
    );
  }

  /**
   * Raven Service - Soft delete notifications by Notification ID
   * @param notificationIds Notification ID(s) of the Notification record(s) to soft delete
   *
   * returns updated list of patron notifications
   */
  softDelete(notificationIds: number[]): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/notifications/deactivate`;

    return this.http.patch<ResponseBase<PatronNotification>>(url, notificationIds).pipe(
      map(rb => {
        const notifications = rb.objects;
        this.notificationsPipe.next(notifications);
        this.tapUnread(notifications);
        this.tapRead(notifications);
        return true;
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            // NOT_FOUND
            // No IDs given or notification record ID was not found in DB
            this.notificationService.showSnackbarError(
              'Could not delete notifications, please refresh and try again.'
            );
          } else if (error.status === 401 || error.status === 403 || error.status === 417) {
            // FORBIDDEN
            // Patron is attempting to modify a notification record they do not own
            this.notificationService.showSnackbarError(
              'Notification record(s) not found or you are not the owner of the notification, please refresh and try again.'
            );
          }
        }
        return of(false);
      })
    );
  }

}
