import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, EMPTY, filter, first, Observable, share, Subject, switchMap, takeUntil} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {NotificationService, PATRON_ITEM_REQUEST_STATUS, PatronItemRequest, PatronItemRequestComment, PatronItemRequestService, PatronService} from '@raven';
import {DeleteItemRequestDialog} from '../dialogs/delete-item-request-dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'rn-item-request-details',
  templateUrl: './item-request-details.component.html',
  styleUrls: ['./item-request-details.component.scss'],
})
export class ItemRequestDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  PATRON_ITEM_REQUEST_STATUS = PATRON_ITEM_REQUEST_STATUS;

  private patronItemRequestId: number;

  patronItemRequest$: Observable<PatronItemRequest>;

  commentsSubject = new BehaviorSubject<PatronItemRequestComment[]>([]);
  readonly comments$ = this.commentsSubject.asObservable();

  destroy$ = new Subject<boolean>();

  messageForm: FormGroup;
  isMobile = false;

  @ViewChild('feed') private readonly _feed: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private patronService: PatronService,
    private patronItemRequestService: PatronItemRequestService,
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.messageForm = this.fb.group({
      message: ['', {validators: []}]
    });

    const patronItemRequestId$ = this.route.paramMap.pipe(
      first(),
      map(paramMap => Number(paramMap.get('id'))),
      filter(id => Number.isInteger(id)),
      tap(id => this.patronItemRequestId = id)
    )

    this.patronItemRequest$ = patronItemRequestId$.pipe(
      switchMap(id => this.patronItemRequestService.getPatronRequest(id)),
      tap((request: PatronItemRequest) => {
        // sort comments here instead of w/ observable chain because newly added comments don't have a timestamp
        const comments = request.comments.sort((a, b) => {
          const aVal = new Date(a.created).getTime();
          const bVal = new Date(b.created).getTime();
          return aVal - bVal;
        })
        this.commentsSubject.next(comments);

        if (request.status !== 'PENDING' && request.status !== 'REQUESTED') {
          this.messageForm.controls.message.disable();
        }
      }),
      share()
    )

    this.patronItemRequest$.pipe(
      filter(request => request.hasUnreadStaffComments),
      switchMap(request => this.patronItemRequestService.markStaffCommentsRead(request.id)),
      takeUntil(this.destroy$),
    ).subscribe();

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = !!breakpointState.matches;
      });
  }

  ngAfterViewChecked() {
    if (this._feed?.nativeElement) {
      this._feed.nativeElement.scrollTop = this._feed?.nativeElement?.scrollHeight;
    }
  }

  deleteItemRequest(request: PatronItemRequest): void {
    const dialogRef = this.dialog.open(DeleteItemRequestDialog, {
      data: {itemRequests: [request], cantDelete: 0},
      maxWidth: '95vw',
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((deleteConfirmation: boolean) => {
          return deleteConfirmation ? this.patronItemRequestService.cancel(request.id) : EMPTY;
        })
      )
      .subscribe((success) => {
        if (success) {
          this.notificationService.showSnackbarSuccess('Request successfully canceled');
        }
        this.router.navigate(['/patron-item-requests-v2']);
      });
  }

  postMessage(): void {
    const message = this.messageForm.controls.message.value.trim();
    const messageControl = this.messageForm.controls.message;

    if (!message) return;

    this.patronItemRequestService.createPatronComment(this.patronItemRequestId, message)
      .pipe(first())
      .subscribe(result => {
        if (result) {
          const newComment = {
            organizationId: this.patronService.patron.organizationId,
            patronId: this.patronService.patron.id,
            userId: null,
            patronItemRequestId: this.patronItemRequestId,
            comment: message,
            created: new Date().toDateString()
          }
          this.commentsSubject.next([...this.commentsSubject.value, newComment]);
          messageControl.setValue('');
        }
      })
  }

  getPatronInitials(): string {
    const {firstName = '', lastName = ''} = this.patronService.patron;
    if (firstName && lastName) {
      return (firstName[0] + lastName[0]).toUpperCase();
    } else {
      return (firstName + lastName).slice(0, 2).toUpperCase() || 'PA';
    }
  }

  shouldShowDivider(index: number): boolean {
    if (index === 0) return true; // Always show first divider
    const comments = this.commentsSubject.value;
    const currentCommentDate = new Date(comments[index].created).setHours(0, 0, 0, 0);
    const previousCommentDate = new Date(comments[index - 1].created).setHours(0, 0, 0, 0);
    return currentCommentDate !== previousCommentDate;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
