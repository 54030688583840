import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

/*
  Copied from part of Hawk's common validators to replace the Angular v15-incompatible third-party validator we were using.  We should probably put common
  validators somewhere central to both UIs.
*/

export class CommonValidators {
  /** This validator function could be used directly, but it's probably better to use it as a base from which to create other custom validators. */
  public static pattern(pattern: string | RegExp, errorObject: object): ValidatorFn {
    return (control: AbstractControl) => Validators.pattern(pattern)(control) ?? errorObject;
  }

  public static phoneNumber(control: AbstractControl): ValidationErrors | null {
    /*
      North American phone number rules:
        - Area codes start with a number 2–9, followed any two digits.  (But there's also a rule that the last two digits can't both be 11, because of 911.)
        - The exchange code starts with a number 2–9 followed by any two digits.
        - The final four digits, known as the station code, have no restrictions.

      For this, the pattern would be something like /^\(?[2-9]\d{2}\)?[\s-]?[2-9]\d{2}[\s-]?\d{4}$/ -- not counting the "no *11" rule.

      But for now, so as to not introduce a lot of problems with test data, we're simply validating a "reasonably formatted" 10-digit phone number.
    */
    const rawValue: string = control.value?.replace(/\D/g, '') ?? ''; // Remove all non-digit characters.
    if (!rawValue) return null;

    return rawValue.length === 10
      ? CommonValidators.pattern(/^\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/, {mustBeValidPhoneNumber: true})(control)
      : {mustBeTenDigitPhoneNumber: true};
  }
}