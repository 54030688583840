import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {AuthService} from '@raven';

@Component({
  selector: 'rn-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  message: string;

  constructor(
    public authService: AuthService,
    public router: Router,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    // log the person out
    this.authService.logout()
      .then(() => {
        // send to the index
        // this WAS happening before the logout finished running.... OOPS
        window.location.href = `/`;
      });
  }
}
