<div [rvIsLoading]="!(preferences$ | async)">
  <form [formGroup]="notificationPreferencesForm" novalidate>
    <div class="mat-display-1 preferences-title">Notification Preferences</div>
    <h3 class="preferences-subtitle">Please select your notification preferences for your library account.</h3>
    <div class="flex-row-wrap-col-responsive preferences-contact-row">
      <ng-container *ngTemplateOutlet="inputField; context: { name: 'notificationEmail', label: 'Notification Email', errorLabel: 'notification email' }">
      </ng-container>
      <ng-container *ngTemplateOutlet="inputField; context: { name: 'phone', label: 'Mobile Phone Number', errorLabel: 'phone number' }">
      </ng-container>
      <ng-template #inputField let-name="name" let-label="label" let-errorLabel="errorLabel">
        <div class="preferences-field">
          <div class="preferences-field-label">{{ label }}</div>
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControlName]="name"/>
            <mat-error *ngIf="notificationPreferencesForm.controls[name]?.invalid">
              {{ getErrorMessage(errorLabel, notificationPreferencesForm.controls[name]) }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-template>
    </div>
    <hr class="catalog-hr"/>
    <div class="mat-display-1 preferences-title">Required Notice Delivery Method</div>
    <div class="flex-col flex-end-start">
      <div class="preferences-select-column">
        <ng-container *ngTemplateOutlet="selectField; context:
          { name: 'accountNotifications', label: 'Account related notices', toggle: false,
          description: 'Holds are ready for pickup, responses to item requests, etc.' }">
        </ng-container>
        <ng-container *ngTemplateOutlet="selectField; context:
          { name: 'receiptNotifications', label: 'Receipts', toggle: false,
          description: 'Balance payment receipts, check out receipts, etc.' }">
        </ng-container>
        <ng-container *ngTemplateOutlet="selectField; context:
          { name: 'renewNotifications', label: 'Almost overdue/Auto renew', toggle: false,
          description: 'Upcoming due dates, items have been renewed, etc.' }">
        </ng-container>
        <ng-container *ngTemplateOutlet="selectField; context:
          { name: 'expirationNotifications', label: 'Library card expiration', toggle: false,
          description: 'Upcoming and past expiration date notices' }">
        </ng-container>
      </div>
    </div>
    <!--    <div class="mat-display-1 preferences-title">Additional Notices</div>-->
    <!--    <div class="flex-col flex-end-start">-->
    <!--      <div class="preferences-select-column">-->
    <!--        <ng-container *ngTemplateOutlet="selectField; context:-->
    <!--          { name: 'localEvents', label: 'Local Library Events', toggle: true,-->
    <!--          description: 'Storybook time, sales, summer reading lists, etc.' }">-->
    <!--        </ng-container>-->
    <!--        <ng-container *ngTemplateOutlet="selectField; context:-->
    <!--          { name: 'monthlyNewsletter', label: 'Monthly Newsletter', toggle: true,-->
    <!--          description: 'Closure notifications, what’s new, etc.' }">-->
    <!--        </ng-container>-->
    <!--      </div>-->
    <!--    </div>-->

    <ng-template #selectField let-name="name" let-label="label" let-description="description" let-toggle="toggle">
      <div class="flex-row-wrap-col-responsive flex-start-space-between preferences-contact-row">
        <div class="flex-row">
          <div class="preferences-toggle">
            <mat-slide-toggle *ngIf="toggle" color="primary" [formControlName]="name+'Toggle'"></mat-slide-toggle>
          </div>
          <div class="preferences-select-label-container">
            <h3 class="preferences-select-label">{{ label }}</h3>
            <div class="preferences-select-description">{{ description }}</div>
          </div>
        </div>
        <mat-form-field appearance="outline" class="preferences-select-field">
          <mat-select [formControlName]="name">
            <mat-option *ngFor="let preferenceSelection of NOTIFICATION_PREFERENCE_SELECTION" [value]="preferenceSelection.value">
              {{ preferenceSelection.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
    <div class="clear-for-footer"></div>
    <div class="save-footer mat-elevation-z6">
      <div class="save-footer-content flex-row flex-center-end">
        <div class="spacer"></div>
        <div class="button-row-right">
          <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel"
                  (click)="cancel()" data-cy="on-hold-button">
            Cancel
          </button>
          <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                  [disabled]="notificationPreferencesForm.pristine || notificationPreferencesForm.invalid" (click)="submit()">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
