<div class="page-body-wrapper" [rvIsLoading]="listDataService.loading">
  <rn-account-status-warning context="banner"></rn-account-status-warning>
  <div class="flex-row flex-center-space-between">
    <div class="mat-display-1">Items Out <span class="checkout-count">&nbsp;({{ listDataService.totalCount }})</span></div>
    <rn-checkout-filter style="margin: 8px 0 0 auto"></rn-checkout-filter>
  </div>
  <div class="select-all">
    <mat-checkbox (change)="$event ? toggleAll() : null"
                  [checked]="selection.hasValue() && allSelected()"
                  [indeterminate]="selection.hasValue() && !allSelected()">
      Select All
    </mat-checkbox>
  </div>
  <table [dataSource]="listDataService.data" aria-label="Items Out" class="non-mobile-table" mat-table>
    <ng-container matColumnDef="checkbox">
      <th *matHeaderCellDef mat-header-cell scope="col"></th>
      <td (click)="$event.stopPropagation()" *matCellDef="let checkout" mat-cell>
        <mat-checkbox (change)="$event ? selection.toggle(checkout) : null"
                      [checked]="selection.isSelected(checkout)">
        </mat-checkbox>&nbsp;&nbsp;
      </td>
    </ng-container>
    <ng-container matColumnDef="mobile">
      <th *matHeaderCellDef mat-header-cell scope="col"></th>
      <td *matCellDef="let checkout" class="pointer" mat-cell>
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{checkout: checkout}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th *matHeaderCellDef mat-header-cell scope="col">Title</th>
      <td *matCellDef="let checkout" class="table-row-title pointer" mat-cell>
        <span>{{ checkout.itemTitle }}</span><br/>
        <span>{{ checkout.itemBarcode }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="mediaType">
      <th *matHeaderCellDef mat-header-cell scope="col">Media Type</th>
      <td *matCellDef="let checkout" class="pointer" mat-cell>
        {{ checkout.itemMediaType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="checkoutDate">
      <th *matHeaderCellDef mat-header-cell scope="col">Checkout Date</th>
      <td *matCellDef="let checkout" class="pointer" mat-cell>
        {{ checkout.checkoutTime | date }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastRenewal">
      <th *matHeaderCellDef mat-header-cell scope="col">Last Renewal</th>
      <td *matCellDef="let checkout" class="pointer" mat-cell>
        {{ checkout.lastRenewal | date }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <th *matHeaderCellDef mat-header-cell scope="col">Due Date</th>
      <td *matCellDef="let checkout" class="pointer" mat-cell>
        {{ checkout.dueDate | date }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell scope="col">Status</th>
      <td (click)="$event.stopPropagation()" *matCellDef="let checkout" mat-cell>
        <rn-checkout-status [checkout]="checkout"></rn-checkout-status>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
      <td (click)="$event.stopPropagation()" *matCellDef="let checkout" mat-cell>
        <div class="flex-row-col-responsive">
                    <span (click)="renewCheckout([checkout])" [matTooltip]="getTooltipText(checkout)"
                          [ngClass]="{ 'disabled-icon': !isRenewable(checkout) }" class="ri-refresh-line action-icon pointer"
                          matTooltipPosition="above"></span>
          <span (click)="submitClaim(checkout)" *ngIf="!checkout.claimId"
                class="ri-draft-line action-icon pointer" matTooltip="Submit Claim"
                matTooltipPosition="above"></span>
        </div>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="columnsToDisplay" class="mx-bold-underline" mat-header-row></tr>
    <tr (click)="routingService.goToCatalogRecordDetail(myRowData.catalogRecordId)"
        *matRowDef="let myRowData; columns: columnsToDisplay"
        class="mx-taller-row"
        mat-row></tr>
  </table>
  <div *ngIf="listDataService.totalCount == 0 && !(listDataService.filter | async)" class="empty-list">
    No items out.
  </div>
  <div *ngIf="listDataService.totalCount == 0 && (listDataService.filter | async)" class="empty-list">
    No items out matching current activity filters.
  </div>
  <rn-pager></rn-pager>
  <div class="clear-for-footer"></div>
  <div class="save-footer" style="background-color: white">
    <div class="save-footer-content flex-row flex-center-end">
      <div class="spacer"></div>
      <div class="button-row-right">
        <button (click)="renewCheckout(selection.selected)" [disabled]="!selectionHasRenewableItems()"
                class="ButtonSmallBlack save-button-group save-button"
                color="primary" mat-raised-button>
          Renew Selected
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #mobileTableEntry let-checkout="checkout">
  <div class="mobile-table-entry flex-col">
    <a class="mobile-title-link">
      {{ checkout.itemTitle }}<br/>
      {{ checkout.itemBarcode }}
    </a>
    <span>Media Type: {{ checkout.itemMediaType }}</span>
    <span>Checkout Date: {{ checkout.checkoutTime | date }}</span>
    <span>Last Renewal: {{ checkout.lastRenewal | date }}</span>
    <span>Due Date: {{ checkout.dueDate | date }}</span>
    <rn-checkout-status [checkout]="checkout"></rn-checkout-status>
  </div>
</ng-template>
