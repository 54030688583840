import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {HttpErrorInterceptor} from './core/http/http-error.interceptor';
import {LoginComponent} from './core/login/login.component';
import {LogoutComponent} from './core/login/logout.component';
import {JWT_OPTIONS, JwtConfig, JwtModule} from '@auth0/angular-jwt';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {AppLayoutComponent} from './_layout/app-layout.component';
import {AppRoutingModule} from './app.routes';
import {IndexComponent} from './core/index/index.component';
import {RoutesModule} from './core/routes/routes.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import {NonBreakingPipe} from './core/shared/pipes/non-breaking.pipe';
import {AuthService} from './core/authentication/auth.service';
import {PatronBillingModule} from './core/patron-billing/patron-billing.module';
import {HoldsModule} from './core/holds/holds.module';
import {VerificationModule} from './core/verification/verification.module';
import {RegistrationModule} from './core/registration/registration.module';
import {CurrencyPipe, DatePipe, TitleCasePipe} from '@angular/common';
import {LandingComponent} from './core/login/landing.component';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';
import {CheckoutsModule} from './core/patron-checkouts/checkouts.module';
import {ProfileModule} from './core/profile/profile.module';
import {CatalogModule} from './core/catalog/catalog.module';
import {FavoritesModule} from './core/favorites/favorites.module';
import {ErrorModule} from './core/error-pages/error.module';
import {ItemRequestsModule} from './core/item-requests/item-requests.module';
import {SharedModule} from './core/shared/shared.module';
import {PatronNotificationsModule} from './core/patron-notfications/patron-notifications.module';
import {ForgotPasswordDialogComponent} from './core/login/forgot-password-dialog.component';
import {HttpHeaderInterceptor} from './core/http/http.headers.interceptor';
import {SnackbarNotificationComponent} from './core/shared/snackbar-notification.component';
import {Environment} from './model/environment';
import {OrganizationService} from './services/organization.service';
import {Organization} from './model/organization';
import {globalReducer, initialState} from '@raven';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {firstValueFrom} from 'rxjs';
import {DateFnsAdapter} from '@angular/material-date-fns-adapter';
import {enUS} from 'date-fns/locale';

// jwt config
export function jwtOptionsFactory(authService: AuthService): JwtConfig {
  return {
    tokenGetter: () => authService.getAsyncToken(),
    allowedDomains: [
      'localhost:9142',
      environment.apiUrl.split('/')[2], // just the domain, remove "https://" and "/api"
    ],
  };
}

export function setupOrganization(organizationService: OrganizationService,
                                  authService: AuthService) {
  return () => {
    return firstValueFrom(organizationService.setupOrganization())
      .then((org: Organization) => {
        return authService.firebaseAuthInitialized.then(() => {
          authService.setFirebaseTenant(org.googlePatronTenantId);
        });
      });
  }
}

export function firebaseAuthInitialized(authService: AuthService) {
  return () => authService.firebaseAuthInitialized;
}

function isProductionOrStaging(): boolean {
  return environment.name === 'stage' || environment.name === 'prod';
}

const RV_DATE_FORMATS: MatDateFormats = {
  parse: {dateInput: 'MM/dd/yyyy'},
  display: {
    dateInput: 'MM/dd/yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'MM/dd/yyyy',
    monthYearA11yLabel: 'MM yyyy',
  }
};

@NgModule({
  imports: [
    BrowserModule,
    // JWT support
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),
    // ngrx stuff
    // NxModule.forRoot(),
    StoreModule.forRoot({app: globalReducer}, {initialState}),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25,
      })
      : [],
    HttpClientModule,
    // FIREBASE
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    // Google Analytics
    //AngularFireAnalyticsModule,
    //AngularFirePerformanceModule,
    // our stuff
    CatalogModule,
    PatronBillingModule,
    PatronNotificationsModule,
    ItemRequestsModule,
    HoldsModule,
    CheckoutsModule,
    RegistrationModule,
    ProfileModule,
    FavoritesModule,
    RoutesModule,
    AppRoutingModule,
    ErrorModule, // global routing
    VerificationModule,
    SharedModule,
    // Core Modules
    MatIconModule,
  ],
  declarations: [
    AppComponent,
    AppLayoutComponent,
    IndexComponent,
    LandingComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordDialogComponent,
    SnackbarNotificationComponent
    // PageNotFoundComponent
  ],
  providers: [
    //PerformanceMonitoringService,
    //UserTrackingService,
    //ScreenTrackingService,
    DatePipe,
    CurrencyPipe,
    TitleCasePipe,
    NonBreakingPipe,
    {provide: Environment, useValue: environment},
    {provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: DateAdapter, useClass: DateFnsAdapter},
    {provide: MAT_DATE_FORMATS, useValue: RV_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: enUS},
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({showDialog: true}),
    },
    // {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      // deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupOrganization,
      deps: [OrganizationService, AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService) => () => authService.initializePatronLogin(),
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
