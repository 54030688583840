<div class="rv-top-banner">
  <mat-toolbar class="mat-toolbar mat-toolbar-single-row rv-top-nav">
    <button class="top-sidenav-toggle hide-gt-xs" *ngIf="navbarVisible" (click)="sidenav.toggle()" mat-icon-button>
      <mat-icon>{{ !opened ? 'menu' : 'close' }}</mat-icon>
    </button>
    <div aria-label="Home" class="home-link" (click)="goToDashboard()">
      <img alt="Millonex Logo" src="/assets/mx-logo.png"/>
      <h3 class="mat-h3">{{ (organizationService.organization$ | async)?.name }}</h3>

    </div>
    <div class="spacer"></div>
    <!--    Keep search bar for future implementation but hide for now, see MX-969 -->
    <!--    <fn-search-bar *ngIf="patron && layoutService.navbarVisible"></fn-search-bar>-->
    <div class="spacer"></div>
    <rn-account-status-warning context="dropdown"></rn-account-status-warning>
    <rn-notification-center *ngIf="patron && navbarVisible"></rn-notification-center>
    <div *ngIf="(patron && navbarVisible) else noPatron" class="patron-menu">
      <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="profileMenu" style="margin-right: -12px">
        <img class="patron-circle" alt="profile" src="/assets/slicons/profile_circled.svg"/>
        <span class="hide-xs">{{ getPatronName() }}  </span>
        <img class="menu-chevron" alt="" src="/assets/slicons/chevron_down.svg" *ngIf="!menuTrigger.menuOpen"/>
        <img class="menu-chevron" alt="" src="/assets/slicons/chevron_up.svg" *ngIf="menuTrigger.menuOpen"/>
      </button>
      <mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="routingService.goToProfile(); sidenav.close()">
          <span i18n="@@shared_account">Account</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingService.goToPatronBilling(); sidenav.close()">
          <span i18n="@@shared_payment">Balance & Payments</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingService.goToPatronPaymentInfo(); sidenav.close()">
          <span i18n="@@shared_payment">Payment Information</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingService.goToNotificationPreferences(); sidenav.close()">
          <span i18n="@@shared_com_pref">Notification Preferences</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/logout">
          <span i18n="@@shared_logout">Sign out</span>
        </button>
      </mat-menu>
    </div>
    <ng-template #noPatron>
      <div class="patron-menu">
        <ng-container *ngIf="(patronService.patron$ | async) else showLogin">
          <!-- this case for users logged in as PatronPending -->
          <button mat-button (click)="router.navigateByUrl('/logout')" class="active-link" style="font-weight:bold;font-size:0.8em;">
            Logout
          </button>
        </ng-container>
        <ng-template #showLogin>
          <button mat-button (click)="patronService.openLoginDialog()">
            <img class="patron-circle" alt="profile" src="/assets/slicons/profile_circled.svg"/>
            <span style="margin-left: 5px">Log in</span>
          </button>
        </ng-template>
      </div>
    </ng-template>
  </mat-toolbar>
</div>
<div *ngIf="!mobile && navbarVisible" class="rv-nav-banner mat-elevation-z6">
  <mat-toolbar class="mat-toolbar mat-toolbar-single-row horizontal-nav">
    <mat-nav-list class="flex-row">
      <!--https://material.angular.io/components/list/overview-->
      <ng-container *ngTemplateOutlet="mainNavListItems"></ng-container>
    </mat-nav-list>
    <div class="spacer"></div>
  </mat-toolbar>
</div>
<mat-sidenav-container style="background-color: inherit; height: calc(100vh - 60px - 74px);" [hasBackdrop]="false">
  <mat-sidenav #sidenav class="rn-sidenav" position="start" [(opened)]="opened" (closedStart)="blur()" [mode]="sideNavMode" [fixedInViewport]="true"
               [fixedTopGap]="74">
    <mat-nav-list *ngIf="mobile && navbarVisible" class="flex-col">
      <ng-container *ngTemplateOutlet="mainNavListItems"></ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="main-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="footerVisible" class="clear-for-footer"></div> <!-- clear space for the fixed footer -->
<div class="rv-footer-banner hide-xs" *ngIf="footerVisible  && !sidenav.opened">
  <div class="rv-footer-content flex-row flex-center-space-between">
    <div>
      <span class="ButtonsSmallGray">Powered by Simplicity&trade; All rights reserved ©2023</span>
    </div>
  </div>
</div>

<ng-template #mainNavListItems>
  <a mat-list-item routerLink="/dashboard" routerLinkActive="active-route" (click)="sidenav.close()" class="hide-xs">
    <span class="mat-small main-link">Library Home</span>
  </a>
  <a mat-list-item routerLink="/catalog" routerLinkActive="active-route" (click)="sidenav.close()">
    <img alt="" src="/assets/slicons/book.svg" class="nav-icon hide-gt-xs">
    <span class="mat-small main-link">Catalog</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <a *ngIf="patron" mat-list-item routerLink="/checkouts" routerLinkActive="active-route" (click)="sidenav.close()">
    <img alt="" src="/assets/simplicity-icon/timed.svg" class="nav-icon hide-gt-xs">
    <span class="mat-small main-link">Items Out</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <a *ngIf="patron" mat-list-item routerLink="/holds" routerLinkActive="active-route" (click)="sidenav.close()">
    <img alt="" src="/assets/simplicity-icon/hold.svg" class="nav-icon hide-gt-xs">
    <span class="mat-small main-link">On Hold</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <a *ngIf="patron" mat-list-item routerLink="/patron-item-requests-v2" routerLinkActive="active-route" (click)="sidenav.close()">
    <em class="nav-icon ri-user-voice-line hide-gt-xs" style="padding-left: 4px; margin-right: 11px;"></em>
    <span class="mat-small main-link">Requests</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <a *ngIf="patron" mat-list-item routerLink="/favorites" routerLinkActive="active-route" (click)="sidenav.close()">
    <img alt="" src="/assets/slicons/heart.svg" class="nav-icon hide-gt-xs">
    <span class="mat-small main-link">Favorites</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <a *ngIf="patron" mat-list-item routerLink="/my-card" routerLinkActive="active-route" (click)="sidenav.close()">
    <img alt="" src="/assets/simplicity-icon/cards.svg" class="nav-icon hide-gt-xs">
    <span class="mat-small main-link">Library Card</span>
    <img alt="" src="/assets/chevron-bottom.svg" class="nav-chevron hide-gt-xs">
  </a>
  <hr class="hide-gt-xs" *ngIf="patron">
  <div class="spacer hide-gt-xs"></div>
  <mat-list-item (click)="sidenav.close()" style="height: 42px;" class="hide-gt-xs">
    <a>
      <span class="mat-small">Submit Feedback</span>
    </a>
  </mat-list-item>
  <mat-list-item (click)="sidenav.close()" style="height: 42px;" class="hide-gt-xs">
    <a>
      <span class="mat-small">Customer Support</span>
    </a>
  </mat-list-item>
  <mat-list-item (click)="sidenav.close()" style="height: 42px;" class="hide-gt-xs" *ngIf="patron">
    <a routerLink="/logout">
      <span class="mat-small">Logout</span>
    </a>
  </mat-list-item>
</ng-template>
